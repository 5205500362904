import * as yup from 'yup';

export const siteDetailsSchema = yup.object().shape({
  email: yup.string().required('Email address is required').email('Email address is invalid'),
  addressLine1: yup.string(),
  addressLine2: yup.string(),
  country: yup.string(),
  county: yup.string(),
  postcode: yup.string(),
  mobile: yup.string(),
  phoneNumber: yup.string(),
  city: yup.string(),
});
