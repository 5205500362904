import Navbar from '@shared/components/Navbar';
import { H3 } from '@shared/components/Typography';
import { Helmet } from 'react-helmet-async';

import * as S from './styles';

const BookAppointmentPage = () => {
  return (
    <>
      <Helmet>
        <title>PHMG | Book an appointment</title>
        <meta name="description" content="Welcome to the PHMG Client Portal." />
      </Helmet>
      <Navbar />
      <S.AccountManagerWrapper>
        <S.Header>
          <H3>Book an appointment</H3>
        </S.Header>
      </S.AccountManagerWrapper>
    </>
  );
};

export default BookAppointmentPage;
