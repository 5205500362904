import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: grid;
  grid-template-rows: 81px calc(100vh - 81px); /* height limitation on second row */
  grid-template-columns: 220px auto;
  grid-template-areas:
    'header  header'
    'sidebar content';

  @media (max-width: ${size.large}) {
    display: block;
  }
`;

export const Container = styled.div<{ bgColor?: string }>`
  grid-area: content;
  overflow: auto; // keeps sidebar and header fixed in place when scrolling
  background-color: ${({ bgColor }) => bgColor};
`;

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
