import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import axios from 'axios';

import { logoutHandler } from '../userSlice';

type RefreshTokenResponse = {
  data: {
    AuthenticationResult: {
      AccessToken: string;
    };
  };
};

type TokenExpiredResponse = {
  message: string;
  statusCode: number;
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_BASE_URL}/api`,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithRefresh: BaseQueryFn<
  string | FetchArgs, 
  unknown, 
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401 && (result.error.data as TokenExpiredResponse).message === 'jwt expired') {
    // try to get a new token
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      const { data: tokenResponse } = await axios.post<RefreshTokenResponse>(
        `${import.meta.env.VITE_API_BASE_URL}/api/auth/refresh-token`,
        {
          refreshToken: refreshToken,
        }
      );
      const { data } = tokenResponse;
      localStorage.setItem('accessToken', data.AuthenticationResult.AccessToken);
    } catch (error) {
      // Logout user if we cant refresh the token for whatever reason
      console.error(error);
      api.dispatch(logoutHandler());
    }
    // retry the initial query
    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};
