import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    width: 100%;
    text-align:center;
    color: white;

    h2{
      font-size: 38px;
    }


    h4,p{
      margin: 20px auto;
    }

    p{
      color: #FFFFFF;
    opacity: 0.5;
    word-break: break-word;
    max-width: 500px;
    margin-bottom: 40px;
    }

    .values-icon {
      width: 64px;
      height: auto;
      margin-bottom: 20px;
    } 
`

export const ValuesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  max-width: 1600px; 
  margin: 0 auto; 
`;

export const BrandValueButton = styled.button`
    background: none;
    color: inherit;
 
    border: 2px solid #FFFFFF;
    border-radius: 16px;
    padding: 20px 115px;
   
    font: inherit;
    cursor: pointer;
    outline: inherit;

    span {
      font-size: 20px;
    }


  @media (max-width: ${size.large}) {
    padding: 20px 115px;
   

 }
`;
