import * as S from './styles';

type Props = {
    brandValues: Array<string>;
};

const BrandValues = ({ brandValues } : Props) => {
 
  return (
    <S.Wrapper>

      <img src="https://phmg-styles.s3.eu-west-2.amazonaws.com/icons/brand-values-icon.svg" className="values-icon" alt="Your Brand Values"/>
      <h2>Your Brand Values</h2>
      <p>
        You told us the key words that underpin your business. 
        We’ll use these as a foundation in building your sonic identity.
      </p>
   
      <S.ValuesWrapper>
        {brandValues?.map((value) => (
          <S.BrandValueButton>
            <span>{value}</span>
          </S.BrandValueButton>
        ))}
      </S.ValuesWrapper>

    </S.Wrapper>
  )
}

export default BrandValues;
