import Navbar from '@shared/components/Navbar';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  return (
    <>
      <Helmet title="PHMG | Forgot Password" />
      <Navbar />

      <S.ForgotPasswordWrapper>
        <S.FormWrapper>
          <ForgotPasswordForm />
        </S.FormWrapper>
      </S.ForgotPasswordWrapper>
    </>
  );
};

export default ForgotPasswordPage;

const S: any = {};

S.ForgotPasswordWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

S.FormWrapper = styled.div`
  max-width: 400px;
  margin: 50px auto;
`;
