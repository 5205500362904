import { SensitiveInput } from '@phmg/storybook/v1/component';
import { size } from '@shared/utils/breakpoints';
import { Form } from 'formik';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FormStyles = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SensitiveInputStyled = styled(SensitiveInput)`
  margin-top: 0px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const ErrorMessage = styled.p`
  color: var(--red);
  line-height: 1.5rem;
  font-weight: 300;
  margin: 20px 0 20px;
`;

export const ForgotPasswordLink = styled(Link)`
  text-transform: uppercase;
  display: block;
  margin: 22px 0 0;
  color: #474747;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  text-decoration: underline;
`;

export const BottomText = styled.p`
  line-height: 1.5rem;
  font-weight: 300;

  a {
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    transition: 0.15s ease;
    color: inherit;
    text-decoration: underline;

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  margin-top: 40px;
  font-size: 1rem;
  color: #474747 !important;

  @media (min-width: ${size.medium}) {
    margin: auto 0 0 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
`;
