import styled from 'styled-components';

export const LocationListingStyles = styled.a`
  width: 100%;
  height: 60px;
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  transition: 0.3s ease;
  text-decoration: none;
  color: var(--black);
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
    transform: translateY(-2px);
  }

  @media (min-width: 768px) {
    padding: 0 30px;
  }
`;

export const Title = styled.p`
  font-size: 1rem;
  font-weight: 300;

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`;

export const Button = styled.div`
  color: var(--red);
  background: var(--red-pink-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  transition: 0.15s ease;

  svg {
    position: relative;
    top: 2px;
    margin-left: 18px;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`;
