import { Input } from '@phmg/storybook/v1/component';
import { size } from '@shared/utils/breakpoints';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  /* display: grid; */
  grid-template-columns: repeat(12, 1fr);
  column-gap: 30px;
`;

export const FormStyles = styled(Form)`
  /* display: grid; */
  grid-template-columns: repeat(12, 1fr);
  row-gap: 20px;
  width: 100%;
  margin: 30px 0px;
  -webkit-box-align: baseline;
  align-items: baseline;

  @media (min-width: ${size.medium}) {
    margin: 30px 0px 80px;
    grid-column: 2 / -2;
  }
`;

export const Header = styled.div`
  /* grid-column: 1 / -1; */
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
`;

export const InputStyled = styled(Input)`
  margin-top: 0px;
  margin-bottom: 10px;

  @media (min-width: ${size.medium}) {
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
`;
