import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 3.75rem;
  letter-spacing: -1px;
  line-height: 4.125rem;
  font-weight: 400;
`;

export const H2 = styled.h2`
  font-size: 3.125rem;
  letter-spacing: -0.5px;
  line-height: 3.4375rem;
  font-weight: 400;
`;

export const H3 = styled.h3`
  font-size: 1.625rem;
  letter-spacing: -0.25px;
  line-height: 1.875rem;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
`;

export const H4 = styled.h4`
  font-size: 1.625rem;
  letter-spacing: -0.25px;
  line-height: 1.875rem;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 1.875rem;
    letter-spacing: 0px;
    line-height: 2.25rem;
  }
`;

export const H5 = styled.h5`
  font-size: 1.375rem;
  letter-spacing: 0px;
  line-height: 1.75rem;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 1.625rem;
    line-height: 2.1865rem;
  }
`;

export const H6 = styled.h6`
  font-size: 1.25rem;
  letter-spacing: 0px;
  line-height: 1.625rem;
  font-weight: 400;
`;

export const Copy = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;

  a {
    font-weight: 400;
    color: inherit;
    white-space: nowrap;
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: 0.15s ease;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-image: var(--red-pink-gradient);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
`;
