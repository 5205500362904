import { Icon } from '@phmg/storybook/v1/component';
import { Link } from 'react-router-dom';

import * as S from './styles';
interface NavbarProps {
  /**
   * Link for back to main site
   */
  brandlink: string;
}

/**
 * Primary UI component for user interaction
 */
const Navbar = ({ brandlink }: NavbarProps) => {
  return (
    <>
      <S.NavbarStyles>
        <S.Brand>
          <Link to={'/'}>
            <Icon glyph={'logo'} size="auto" />
          </Link>
          <S.BrandSeparator />
          <S.BrandLink href={brandlink} target="_blank">
            Back to phmg site
          </S.BrandLink>
        </S.Brand>
      </S.NavbarStyles>
    </>
  );
};

export default Navbar;
