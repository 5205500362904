import { getIsLoggedIn } from '@config/state/globalSlices/userSlice';
import { PageCard } from '@phmg/storybook/v1/component';
import Navbar from '@shared/components/Navbar';
import loginImage from '@shared/icons/login-image.jpg'
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import LoginForm from './components/LoginForm';
import * as S from './styles';

const LoginPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      const redirectUrl = location?.state?.redirect;

      navigate(redirectUrl ?? '/locations')
    }
  }, [isLoggedIn, location?.state?.redirect, navigate]);

  return (
    <>
      <Helmet>
        <title>PHMG | Portal</title>
        <meta name="description" content="Welcome to the PHMG Client Portal." />
      </Helmet>
      <Navbar />
      <S.LoginPageStyles>
        <S.TopSection>
          <S.LoginContainer as="section">
            <S.LoginSection>
              <S.LoginHeader>Client portal login</S.LoginHeader>
              <LoginForm />
            </S.LoginSection>
            <S.ImageSection src={loginImage} alt={'login'} />
          </S.LoginContainer>
        </S.TopSection>

        <S.FeaturedSection>
          <S.FeaturedTitle>Features</S.FeaturedTitle>
          <PageCard
            icon={'invoices'}
            title="Invoices"
            description="Check the status of payments on your account in seconds, and download copies of recent invoices."
          />
          <PageCard icon={'help'} title="Help centre" description="Troubleshoot common problems and find answers to common questions." />
          <PageCard
            icon={'account'}
            title="Account details"
            description="Review and update the details we hold for you, online in moments."
          />
        </S.FeaturedSection>
      </S.LoginPageStyles>
    </>
  );
};

export default LoginPage;
