import * as S from './styles';

type Props = {
  rationale: string;
}

const RationaleComponent = ({ rationale }: Props) => {

  return (
    <S.RationaleWrapper>
      <S.RationaleText >
        {rationale}
      </S.RationaleText>

    </S.RationaleWrapper>
  );
};

export default RationaleComponent;
