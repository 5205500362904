import { useGetAccountManagerQuery, useGetCasesByRecordTypeQuery } from '@config/state/globalSlices/apiSlice/requests';
import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { Case } from '@config/state/globalSlices/apiSlice/types/salesforce';
import { Spinner } from '@phmg/storybook/v1/component';
import CreateCaseForm from '@shared/components/Forms/CreateCaseForm';
import Navbar from '@shared/components/Navbar';
import Table from '@shared/components/Table';
import { H3 } from '@shared/components/Typography';
import { createNotification } from '@shared/utils/createNotification';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import * as S from './styles';

const LocationRequestsPage = () => {
  // Status Filter
  const statusOptions = [
    { id: 'Status', name: 'Status' },
    { id: 'New', name: 'New' },
    { id: 'In Progress', name: 'In Progress' },
    { id: 'Tentative', name: 'Tentative' },
    { id: 'Served Notice', name: 'Served Notice' },
    { id: 'Closed', name: 'Closed' },
  ];
  const [filter, setFilter] = useState('Status');
  const handleChangeFilter = (event: any) => {
    setFilter(event.target.value);
  };

  // Salesforce Id
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetUserQuery();

  const salesforceId = userData?.salesforceId as string;

  // Account Manager
  const {
    data: accountManagerData,
    isLoading: accountManagerIsLoading,
    isError: accountManagerIsError,
    refetch,
  } = useGetAccountManagerQuery(salesforceId, { skip: !salesforceId });
  const accountManager = accountManagerData?.Account_Manager__r;
  // Cases
  const {
    data: casesData,
    isLoading: casesIsLoading,
    isError: casesIsError,
  } = useGetCasesByRecordTypeQuery({ accountId: salesforceId, recordType: 'Engagement' }, { skip: !salesforceId });
  const isLoading = userIsLoading || casesIsLoading || accountManagerIsLoading;
  const isError = userIsError || casesIsError || accountManagerIsError;
  const hasAccountManager = salesforceId && accountManager;

  useEffect(() => {
    if (isError && !isLoading) {
      createNotification({
        message: 'Failed to fetch cases. Please try again.',
        type: 'error',
      });
    }
  }, [isError, refetch, isLoading]);

  return (
    <>
      <Helmet>
        <title>PHMG | Requests</title>
        <meta name="description" content="Welcome to the PHMG Client Portal." />
      </Helmet>
      <Navbar />
      <S.AccountManagerWrapper>
        <S.Header>
          <H3>Submit a new request</H3>
        </S.Header>
        {isLoading && (
          <S.SpinnerContainer>
            <Spinner />
          </S.SpinnerContainer>
        )}
        {hasAccountManager && !isLoading && <CreateCaseForm AccountId={salesforceId} OwnerId={accountManager?.Id} />}
        <br />
        {casesData && !isLoading && (
          <>
            <H3>Requests</H3>
            <S.TableWrapper>
              <Table>
                <Table.Head>
                  <Table.Heading>Case Number</Table.Heading>
                  <Table.Heading>Subject</Table.Heading>
                  <Table.Heading>
                    <select name="subject" onChange={handleChangeFilter}>
                      {statusOptions.map((status) => (
                        <option value={status.name} key={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </select>
                  </Table.Heading>
                </Table.Head>
                <Table.Body>
                  {casesData
                    ?.filter((value) => value?.Status === filter || filter === 'Status')
                    ?.map((value: Case) => (
                      <tr key={value?.CaseNumber}>
                        <td>
                          <p style={{ padding: '10px 30px' }}>{value?.CaseNumber || 'Case number not found'}</p>
                        </td>
                        <td>
                          <p style={{ padding: '10px 30px' }}>{value?.Subject || 'Subject not found'}</p>
                        </td>
                        <td>
                          <p style={{ padding: '10px 30px' }}>{value?.Status || 'Status not found'}</p>
                        </td>
                      </tr>
                    ))}
                </Table.Body>
              </Table>
            </S.TableWrapper>
          </>
        )}
      </S.AccountManagerWrapper>
    </>
  );
};

export default LocationRequestsPage;
