import styled from 'styled-components';

const AccountManagerHeroContainer = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem;
  margin: auto;
  max-width: 100%;
  text-align: center;
  background-color: #f5f5f5;
  border-bottom: 1px solid var(--light-grey);

  h4 {
    max-width: 500px;
    margin: auto;
    font-size: 26px;
    margin-top: 10px;
    font-weight: 500;
  }

  p {
    max-width: 700px;
    margin: 40px auto;
    color: var(--dark-grey);
    font-weight: 500;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 3rem 2rem;
  }
`;

const ImageContainer = styled.div`
  /* margin: 20px auto;
  height: 0px;
  img,
  svg {
    clip-path: circle();
    position: relative;
    bottom: 120px;
    width: auto;
    max-width: 225px;
    height: 100px;
    background-color: white;
  } */

  display: flex;
  justify-content: center;
  marg
`;

export default {
  AccountManagerHeroContainer,
  ImageContainer,
};
