import { Button } from '@phmg/storybook/v1/component';
import { Spinner } from '@phmg/storybook/v1/component';

import AccountManagerModal from '../AccountManagerModal';
import * as S from './styles';

type Props = {
    image: string;
    name: string;
    brandName: string;
    isSubmitting: boolean;
    isAMModalOpen: boolean;
    setAMModalOpen: () => void;
    previewMode: boolean;
};

const AccountManager = ({ image, name, brandName, isSubmitting, isAMModalOpen, setAMModalOpen, previewMode }: Props) => {

  return (
    <S.AccountMangerWrapper>

      <S.Image>

        <div className='am-image-wrapper'>
          <img src={image} alt={name} />
        </div>

      </S.Image>

      {
        name ? (
          <S.TextContentWrapper>
            <h1>Hi, I'm {name}, your implementation manager</h1>
            <p>Hey there! As your dedicated Implementation Manager, 
            I’m here to turn your brand’s story into a captivating  sonic masterpiece. 
            Let’s create magic together!</p>
            <S.SubmitWrapper>
              <Button type="submit" disabled={previewMode} label={'Send message'} onClick={setAMModalOpen}></Button>
  
              {isSubmitting && <Spinner />}
            </S.SubmitWrapper>
  
          </S.TextContentWrapper>

        ) : (
          <S.TextContentWrapper>
            <h1>Get in touch with your Implementation Manager</h1>
            <p>Your dedicated Implementation Manager will help turn your brand’s 
              story into a captivating sonic masterpiece.</p>
            <S.SubmitWrapper>
              <Button type="submit" disabled={previewMode} label={'Send message'} onClick={setAMModalOpen}></Button>
  
              {isSubmitting && <Spinner />}
            </S.SubmitWrapper>
  
          </S.TextContentWrapper>
        )
      }

      {!previewMode && (
        <AccountManagerModal isOpen={isAMModalOpen} setOpen={setAMModalOpen} brandName={brandName}/>
      )}
  
    </S.AccountMangerWrapper>
  )
}

export default AccountManager;
