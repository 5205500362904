import { size } from '@shared/utils/breakpoints';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const DropdownMenu = styled.ul<{darkMode: boolean}>`
  position: fixed;
  top: 70px;
  right: 0;
  width: 100%;
  height: calc(100vh - 70px);
  padding: 0;
  background-color: #fff;
  display: none;
  z-index: 2;

  ul {
    text-decoration: none;
    list-style-type: none;
  }

  p {
    font-size: 16px;
    color: black;
    font-weight: 500;
  }

  ${(props) =>
    props.darkMode &&
    css`
      background-color: rgb(2, 2, 2);
    `}
`;

export const StyledNavLink = styled(NavLink)`
  display: inline-block;
  padding: 20px 20px 20px 25px;
  font-weight: 200;

  display: block;
  width: 100%;
  /* margin: 5px 0; */
  border-bottom: 1px solid var(--light-grey);
  position: relative;

  &.active,
  :hover {
    border-left: ${({ color }) => `7px solid ${color}`};
    padding-left: 20px;

    svg {
      fill: ${({ color }) => color};
    }
  }

  & svg {
    transition: fill 0.3s ease;
  }
  li {
    text-decoration: none;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 25px;
  }
`;

export const DropdownMenuItem = styled.span`
  color: var(--white);
  text-decoration: none;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0px;
  cursor: pointer;
  margin: 0;
  transition: 0.15s ease;
  font-size: 16px;

  &:hover {
    opacity: 0.6;
  }
`;

export const AccountDropdownStyles = styled.li<{menuOpen?: boolean}>`
  position: relative;
  display: none;
  ${(props) =>
    props.menuOpen &&
    css`
      ${DropdownMenu} {
        display: initial;
      }
    `}

  @media (max-width: ${size.large}) {
    display: block;
  }
`;

export const AccountDropdownTrigger = styled.div<{darkMode: boolean}>`
  width: 80px;
  height: 80px;
  align-items: center;
  display: none;
  justify-content: center;
  cursor: pointer;

  svg path {
    fill: var(--black);

    ${(props) =>
    props.darkMode &&
      css`
        fill: var(--white);
      `}
  }

  @media (min-width: ${size.medium}) {
    display: flex;
  }
`;

export const MobileDropdownTrigger = styled(AccountDropdownTrigger)`
  display: flex;
  width: 70px;
  height: 70px;

  @media (min-width: ${size.medium}) {
    display: none;
  }
`;

export const LogoutButton = styled.div`
  display: inline-block;
  padding: 20px 20px 20px 25px;
  font-weight: 200;

  display: block;
  width: 100%;

  border-bottom: 1px solid var(--light-grey);
  position: relative;
  cursor: pointer;

  li {
    text-decoration: none;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 25px;
  }
`;
