import styled from 'styled-components';

const Table = ({ className, children }: any) => <TableStyles className={className}>{children}</TableStyles>;

Table.Head = function TableHead({ children }: any) {
  return (
    <TableHeadStyles>
      <TableHeadRowStyles>{children}</TableHeadRowStyles>
    </TableHeadStyles>
  );
};

Table.Heading = function TableHeading({ children }: any) {
  return <TableHeadingStyles>{children}</TableHeadingStyles>;
};

Table.Body = function TableBody({ children }: any) {
  return <tbody>{children}</tbody>;
};

Table.Row = function TableRow({ children }: any) {
  return <TableRowStyles>{children}</TableRowStyles>;
};

Table.Cell = function TableCell({ children }: any) {
  return <TableCellStyles>{children}</TableCellStyles>;
};

export default Table;

const TableStyles = styled.table`
  width: 100%;
  grid-column: 1 / -1;
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  border-spacing: 0;
  overflow: hidden;
`;

const TableHeadStyles = styled.thead`
  background-color: var(--black);
  height: 40px;

  @media (min-width: 768px) {
    height: 50px;
  }
`;

const TableHeadRowStyles = styled.tr`
  height: 40px;

  @media (min-width: 768px) {
    height: 50px;
  }
`;

const TableHeadingStyles = styled.th`
  color: var(--white);
  font-size: 0.6875rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 10px 30px;

  @media (min-width: 768px) {
    font-size: 0.75rem;
    letter-spacing: 1px;
  }
`;

const TableCellStyles = styled.td`
  padding: 10px 30px;
  border-bottom: 1px solid var(--light-grey);
  font-weight: 300;  
  max-width: 200px;
  overflow-wrap: break-word;
`;

const TableRowStyles = styled.tr`
  background-color: var(--white);
  height: 50px;
  padding: 10px 30px;
  * > {
    padding: 10px 30px;
  }

  &:nth-of-type(odd) {
    background-color: #fbfbfb;
  }

  &:last-of-type {
    ${TableCellStyles} {
      border-bottom: none;
    }
  }

  @media (min-width: 768px) {
    height: 60px;
  }
`;
