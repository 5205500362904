export const size = {
  small: '530px',
  medium: '768px',
  large: '1024px',
  slightlyLarger: '1100px',
};

export const breakpoints = {
  small: `(min-width: ${size.small})`,
  medium: `(min-width: ${size.medium})`,
  large: `(min-width: ${size.large})`,
  slightlyLarger: `(min-width: ${size.slightlyLarger})`,
};
