import styled from 'styled-components';

export const Header = styled.div`
  margin: 20px 0;
`;

export const PageWrapper = styled.div`
  padding: 20px;
`;

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  grid-column: 1 / -1;
  overflow: auto;
`;

export const NoInvoices = styled.div`
  grid-column: 1 / -1;
  text-align: center;
`;
