import { breakpoints } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const FormPanel = styled.div`
  border-radius: 5px;

  max-width: 473px;
  width: 100%;

  @media ${breakpoints.medium} {
    border-radius: 25px;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  padding: 20px 10px;

  @media ${breakpoints.medium} {
    padding: 20px 60px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-top: 5px;

  @media ${breakpoints.medium} {
    justify-content: flex-end;
  }
`;
