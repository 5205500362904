import styled from 'styled-components';

export const DownloadButton = styled.button`
  border: none;
  padding: none;
  color: var(--red);
  cursor: pointer;
  transition: 0.15s ease;
  display: flex;
  align-items: center;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-left: 10px;
  }

  &:disabled {
    opacity: 0.6;
  }

  span {
    background: var(--red-pink-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
