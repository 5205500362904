import styled from 'styled-components';

export const LocationsWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem;
`;

export const Header = styled.div`
  grid-column: 1 / -1;
  margin: 40px 0;
`;

export const LocationsList = styled.div`
  grid-column: 1 / -1;
  margin-top: -15px;
`;

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
