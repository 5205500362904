import { UpdateProfileType } from '@api/types';
import { useSubmitProfileDetailsFormMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { Button, Input } from '@phmg/storybook/v1/component';
import { createNotification } from '@shared/utils/createNotification';
import { Form,Formik } from 'formik';

import { accountSchema } from '../schemas';
import * as S from '../styles';

const AccountForm = ({ userData, refetch }: any) => {

  const [submitForm, { isLoading: isSubmitting, isSuccess }] = useSubmitProfileDetailsFormMutation();
  
  const initialValues = {
    title: userData?.title,
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    email: userData?.email,
  };

  const submitHandler = async (values: UpdateProfileType) => {
    try {
      await submitForm(values).unwrap();

      if (isSuccess) {
        createNotification({
          message: 'Details updated successfully',
          type: 'success',
        });
      }
   
    } catch (error) {
      createNotification({
        message: 'Failed to update details',
        type: 'error',
      });

      refetch();
    }
  };

  return (
    <S.FormPanel>
      <Formik
        initialValues={initialValues}
        validationSchema={accountSchema}
        onSubmit={submitHandler}
        enableReinitialize
        validateOnMount={true}>
        {({ isValid, resetForm }) => (
          <S.FormWrapper as={Form}>
            <Input name="title" type="text" placeholder="Title" label="Title" />
            <Input type="text" name="firstName" placeholder="First name" label="First name" />
            <Input type="text" name="lastName" placeholder="Last name" label="Last name" />
            <Input name="email" type="email" placeholder="Email Address" label="Email Address" />

            <S.Actions>
              <Button label="Cancel" variant="ghost" onClick={resetForm} />
              <Button label="Save" type="submit" disabled={!isValid || isSubmitting} />
            </S.Actions>
          </S.FormWrapper>
        )}
      </Formik>
    </S.FormPanel>
  );
};

export default AccountForm;
