import { SetPasswordType } from '@api/types';
import { useSetPasswordMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { Button, SensitiveInput } from '@phmg/storybook/v1/component';
import Navbar from '@shared/components/Navbar';
import { createNotification } from '@shared/utils/createNotification';
import { Form,Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import { useNavigate,useParams } from 'react-router-dom';

import { passwordFormSchema } from '../schema';
import * as S from '../styles';
import { InitialValuesType } from '../types';

const initialValues: InitialValuesType = {
  password: '',
  confirmPassword: '',
};

const SetPasswordPage = () => {
  const navigate = useNavigate();
  const { rc, u } = useParams();

  const [submitSetPasswordForm, { isLoading: isSubmitting, isSuccess, isError }] = useSetPasswordMutation();

  const handleSubmit = async (values: InitialValuesType) => {
    try {
      const val = {
        username: u,
        newPassword: values.password,
        resetCode: rc,
      } as SetPasswordType;
      await submitSetPasswordForm(val).unwrap();

    } catch (error){
      createNotification({
        message: 'Your password reset token may have expired. Please request a new reset',
        type: 'error',
      });
    }
  };

  return (
    <>
      <Helmet title="PHMG | Change Password" />
      <Navbar />

      <S.SetPasswordWrapper>
        <S.FormWrapper>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={passwordFormSchema}>
            <Form>
              {!isSuccess && (
                <>
                  <S.Heading>Set new password</S.Heading>
                  <S.BodyCopy>Don’t worry, this happens to the best of us.</S.BodyCopy>
                  <SensitiveInput label="New Password" name="password" type="password" placeholder="********" />
                  <SensitiveInput label="Confirm Password" name="confirmPassword" type="password" placeholder="********" />
                  <Button
                    type="submit"
                    wide
                    centre
                    disabled={isSubmitting || isSuccess}
                    label={isSubmitting ? 'Resetting password...' : 'Set new password'}
                  />
                </>
              )}

              {isSuccess && (
                <>
                  <S.Heading>Password updated!</S.Heading>
                  <S.BodyCopy>You're all set.</S.BodyCopy>

                  <Button label="Back to login" wide centre onClick={() => navigate('/ap/login')} />
                </>
              )}

              {isError && (
                <S.ErrorMessage>Oops! Looks like there was an issue submitting the form, please try again!</S.ErrorMessage>
              )}
            </Form>
          </Formik>
        </S.FormWrapper>
      </S.SetPasswordWrapper>
    </>
  );
};

export default SetPasswordPage;
