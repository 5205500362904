import { CaseTypes, RecordTypes } from '@config/state/globalSlices/apiSlice/constants';
import { useGetAccountFilesQuery, useGetAccountQuery, useGetCasesByTypeQuery, useGetSiteDetailsQuery } from '@config/state/globalSlices/apiSlice/requests';
import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { OptionCard } from '@phmg/storybook/v1/component';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import { createNotification } from '@shared/utils/createNotification';
import * as GS from '@shared/utils/globalStyles';
import { useCallback, useEffect,useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as S from './styles';

const LocationPage = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const VITE_SF_FF_ENABLED = JSON.parse(import.meta.env.VITE_SF_FF_ENABLED);
  const [hasProductions, setHasProductions] = useState(false);

  // User Data
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetUserQuery();

  const accountId = userData?.salesforceId as string;

  // Site Details
  const {
    data: siteDetails,
    isLoading: siteDetailsIsLoading,
    isError: siteDetailsisError,
  } = useGetSiteDetailsQuery(siteId || '', { skip: !siteId });

  // Account Details
  const {
    data: accountDetails,
    isLoading: accountDetailsIsLoading,
    isError: accountDetailsIsError,
  } = useGetAccountQuery(accountId, { skip: !accountId || !VITE_SF_FF_ENABLED });
  const accountName = accountDetails?.Name;

  // AWAITING APPROVAL
  const {
    data: copywriterCaseData,
    isLoading: copywriterCaseIsLoading,
    isError: copywriterCaseIsError,
  } = useGetCasesByTypeQuery(
    { accountId: accountId, recordType: RecordTypes.COPYWRITER, caseType: CaseTypes.DEMO },
    { skip: !accountId || !VITE_SF_FF_ENABLED }
  );
  const copywriterCaseNumber = copywriterCaseData?.[0]?.CaseNumber || '';
  if (copywriterCaseNumber && !hasProductions) {
    setHasProductions(true);
  }
  const {
    data: productionCaseData,
    isLoading: productionCaseIsLoading,
    isError: productionCaseIsError,
  } = useGetCasesByTypeQuery(
    { accountId: accountId, recordType: RecordTypes.PRODUCTION, caseType: CaseTypes.DEMO },
    { skip: !accountId || !VITE_SF_FF_ENABLED }
  );
  const productionCaseNumber = productionCaseData?.[0]?.CaseNumber || '';
  if (productionCaseNumber && !hasProductions) {
    setHasProductions(true);
  }

  // APPROVED PRODUCTIONS
  const {
    data: accountFiles,
    isLoading: accountFilesIsLoading,
    isError: accountFilesIsError,
  } = useGetAccountFilesQuery(accountName!, { skip: !accountName || !VITE_SF_FF_ENABLED });
  if (accountFiles && !hasProductions) {
    setHasProductions(true);
  }

  // No Productions or Cases // Is Loading
  const isLoading = (
    userIsLoading || 
    siteDetailsIsLoading || 
    accountDetailsIsLoading || 
    accountFilesIsLoading ||
    copywriterCaseIsLoading || 
    productionCaseIsLoading
  )

  const pageTitle = isLoading ? 'PHMG | Loading' : `PHMG | ${siteDetails?.siteName}`;

  useEffect(() => {
    if (userIsError && !userIsLoading) {
      createNotification({
        message: 'Failed to fetch user details. Please try again.',
        type: 'error',
      });
    }
    if (siteDetailsisError && !siteDetailsIsLoading) {
      createNotification({
        message: 'Failed to fetch site details. Please try again.',
        type: 'error',
      });
    }
    if (accountDetailsIsError && !accountDetailsIsLoading) {
      createNotification({
        message: 'Failed to fetch account details. Please try again.',
        type: 'error',
      });
    }
    if (productionCaseIsError && !productionCaseIsLoading) {
      createNotification({
        message: 'Failed to fetch productions. Please try again.',
        type: 'error',
      });
    }
    if (copywriterCaseIsError && !copywriterCaseIsLoading) {
      createNotification({
        message: 'Failed to fetch scripts. Please try again.',
        type: 'error',
      });
    }
    if (accountFilesIsError && !accountFilesIsLoading) {
      createNotification({
        message: 'Failed to fetch approved productions. Please try again.',
        type: 'error',
      });
    }
  }, [
    userIsError,
    userIsLoading,
    siteDetailsisError,
    siteDetailsIsLoading,
    accountDetailsIsError,
    accountDetailsIsLoading,
    accountFilesIsError,
    accountFilesIsLoading,
    productionCaseIsError,
    productionCaseIsLoading,
    copywriterCaseIsError,
    copywriterCaseIsLoading
  ]);

  const handleOnClick = useCallback(
    (href: string) => {
      navigate(href);
    },
    [navigate]
  );

  return (
    <SidebarPageLayout pageTitle={pageTitle} isLoading={isLoading}>
      {!isLoading && (
        <GS.PageWrapper>
          <S.Heading>
            <h3>{siteDetails?.siteName}</h3>
          </S.Heading>
          {/* <ProgressTracker steps={DEFAULT_PROGRESS_STEPS} activeStep={4} /> */}
          <S.CardsWrapper data-cy="option-cards">
            <OptionCard
              data-cy="invoice-option-card"
              icon="Invoices"
              title="Invoices"
              description="Check the status of payments on your account in seconds, and download copies of recent invoices."
              href={`/locations/${siteId}/invoices`}
              onClick={handleOnClick}
            />
            {VITE_SF_FF_ENABLED && hasProductions && (
              <OptionCard
                data-cy="brand-workshop-card"
                icon="Sound-Wave"
                title="Your Brand Workshop"
                description="Hit play on your unique new productions right here."
                href={`/locations/${siteId}/brand-workshop`}
                onClick={handleOnClick}
              />
            )}
            {VITE_SF_FF_ENABLED && hasProductions && (
              <OptionCard
                data-cy="productions-option-card"
                icon="Productions"
                title="Your productions"
                description="Hit play on your unique past and present productions right here."
                href={`/locations/${siteId}/productions`}
                onClick={handleOnClick}
              />
            )}
            <OptionCard
              data-cy="site-account-option-card"
              icon="Account"
              title="Account details"
              description="Review and update the details we hold for you, online in moments."
              href={`/locations/${siteId}/account`}
              onClick={handleOnClick}
            />
            <OptionCard
              data-cy="help-option-card"
              icon="Help"
              title="Help centre"
              description="Troubleshoot common problems and find answers to common questions."
              href={'https://phmg.com/help-centre'}
              isExternalLink
              onClick={handleOnClick}
            />{' '}
          </S.CardsWrapper>
        </GS.PageWrapper>
      )}
    </SidebarPageLayout>
  );
};

export default LocationPage;
