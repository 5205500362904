import api from '..';
import { LoginParams, LoginResponse } from '../types/auth';

const authRequestsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginParams>({
      query: ({ recaptchaToken, username, password }) => ({
        url: '/auth/login',
        method: 'POST',
        headers: {
          Recaptcha: recaptchaToken,
        },
        body: {
          username: username,
          password: password,
        },
      }),
    }),
  }),
});

export default authRequestsSlice;
