import { Icon } from '@phmg/storybook/v1/component';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import BurgerMenu from '../BugerMenu';
import * as S from './styles';

interface Props {
  /**
   * LocationName
   */
  site: string;
  /**
   * LocationId
   */
  siteId?: string;

  logout: () => void;

  /**
   * Has the site got productions
   */
  hasProductions: boolean;

  isLoading: boolean;
  darkMode: boolean;
}

/**
 * Primary UI component for user interaction
 */
const NavbarActive = ({ logout, hasProductions, darkMode = false }: Props) => {
  const navigate = useNavigate();

  return (
    <S.NavbarWrapper>
      <S.NavbarStyles darkMode={darkMode}>
        <S.Brand>
          <Link to={'/'}>
            <Icon glyph={darkMode ? 'phmg-white-colour' : 'logo'} size="auto" />
          </Link>
        </S.Brand>
        <S.NavLinks darkMode={darkMode}>
          <li>
            <S.NavLink
              onClick={(e) => {
                e.preventDefault();
                navigate('/locations');
              }}
              active={window.location.pathname === '/' || window.location.pathname === '/locations/'}
              darkMode={darkMode}>
              <S.LocationsText>Locations</S.LocationsText>
            </S.NavLink>
          </li>

          <S.UserNavItem>
            <S.NavLink
              onClick={(e) => {
                e.preventDefault();
                navigate('/account');
              }}
              darkMode={darkMode}
              active={window.location.pathname === '/account'}>
              <S.UserItem>
                <Icon glyph="User" size="10px" />
              </S.UserItem>
            </S.NavLink>
          </S.UserNavItem>

          <BurgerMenu logout={logout} menuOpenProp={false} darkMode={darkMode} hasProductions={hasProductions} />

          <S.LogoutNavItem>
            <S.NavLink
              onClick={(e) => {
                e.preventDefault();
                logout();
              }}
              darkMode={darkMode}>
              <S.UserItem>
                <Icon glyph="exit" size="10px" />
              </S.UserItem>
            </S.NavLink>
          </S.LogoutNavItem>
        </S.NavLinks>
      </S.NavbarStyles>
    </S.NavbarWrapper>
  );
};

export default NavbarActive;
