import { useSetPasswordFromProfilePageMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { Button, Input } from '@phmg/storybook/v1/component';
import { createNotification } from '@shared/utils/createNotification';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';

import { setPasswordSchema } from '../schemas';
import * as S from '../styles';

type InitialValuesType = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const SetPasswordForm = () => {

  const [
    submitChangePassword, { isLoading: isSubmitting, isSuccess }
  ] = useSetPasswordFromProfilePageMutation();

  const [initialValues] = useState<InitialValuesType>({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const submitHandler = async (values: InitialValuesType, { resetForm }: FormikHelpers<InitialValuesType>) => {
    try {
      await submitChangePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      });

      if (isSuccess){
        createNotification({
          message: 'Password reset successful',
          type: 'success',
        })
        resetForm();
      }

    } catch (error){
      resetForm();
      createNotification({
        message: 'Failed to update password',
        type: 'error',
      });
    }
  };

  return (
    <S.FormPanel>
      <Formik initialValues={initialValues} validationSchema={setPasswordSchema} onSubmit={submitHandler} enableReinitialize>
        {({ isValid, resetForm, dirty }) => (
          <S.FormWrapper as={Form}>
            <Input name="currentPassword" placeholder="Current Password" label="Current Password" type="password" />
            <Input name="newPassword" placeholder="New Password" label="New Password" type="password" />
            <Input name="confirmNewPassword" placeholder="Confirm New Password" label="Confirm New Password" type="password" />

            <S.Actions>
              <Button label="Cancel" variant="ghost" onClick={resetForm} />
              <Button label="Save" type="submit" disabled={isSubmitting || !(dirty && isValid)} />
            </S.Actions>
          </S.FormWrapper>
        )}
      </Formik>
    </S.FormPanel>
  );
};

export default SetPasswordForm;
