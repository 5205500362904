import { Input } from '@phmg/storybook/v1/component';
import { Form } from 'formik';
import styled from 'styled-components';

export const ModalContainer = styled.div``;

export const Heading = styled.div`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;

  h3 {
    font-size: 20px;
    font-weight: 600;
  }
`;

export const ModalContent = styled.div`
  padding: 0px 0px 28px 0px;
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`;

export const FormStyles = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const InputStyled = styled(Input)`
  margin-top: 0px;
  margin-bottom: 10px;
`;

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
