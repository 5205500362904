import { useGetInvoicesListQuery } from '@config/state/globalSlices/apiSlice/requests';
import { skipToken } from '@reduxjs/toolkit/query/react'
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import Table from '@shared/components/Table';
import { H3 } from '@shared/components/Typography';
import * as GS from '@shared/utils/globalStyles';
import { useParams } from 'react-router-dom';

import InvoiceRow from './components/InvoiceRow';
import * as S from './styles';

type InvoiceData = {
  id: string;
  transactionType: string;
  transactionDate: string;
  transactionStatus: string;
  transactionNumber: string;
};

const InvoicesPage = () => {
  const { siteId } = useParams();

  const {
    data: res,
    isSuccess,
    isLoading,
  } = useGetInvoicesListQuery(siteId ? { siteId } : skipToken);

  return (
    <SidebarPageLayout pageTitle="PHMG | Invoices" isLoading={isLoading}>
      <GS.PageWrapper>
        <S.Header>
          <H3>Invoices</H3>
        </S.Header>

        {isSuccess && res?.data.length === 0 && <S.NoInvoices>No invoices found</S.NoInvoices>}

        {isSuccess && res?.data.length > 0 && (
          <S.TableWrapper>
            <Table>
              <Table.Head>
                <Table.Heading>Type</Table.Heading>
                <Table.Heading>Date</Table.Heading>
                <Table.Heading>Status</Table.Heading>
                <Table.Heading>Reference</Table.Heading>
                <Table.Heading />
              </Table.Head>
              <Table.Body>
                {res?.data.map((invoice: InvoiceData) => (
                  <InvoiceRow
                    key={invoice.id}
                    invoiceId={invoice.id}
                    type={invoice.transactionType}
                    date={invoice.transactionDate}
                    status={invoice.transactionStatus}
                    number={invoice.transactionNumber}
                    siteId={siteId || ''}
                  />
                ))}
              </Table.Body>
            </Table>
          </S.TableWrapper>
        )}
      </GS.PageWrapper>
    </SidebarPageLayout>
  );
};

export default InvoicesPage;
