import { useSubmitAccountManagerFormMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { useGetDynamicsAccountManagerQuery,useGetSiteDetailsQuery } from '@config/state/globalSlices/apiSlice/requests';
import { Spinner } from '@phmg/storybook/v1/component';
import { Button, Dropdown } from '@phmg/storybook/v1/component';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import { createNotification } from '@shared/utils/createNotification';
import { Formik, FormikState } from 'formik';
import { useParams } from 'react-router-dom';

import AccountManagerHero from './components/AccountManagerHero';
import { accountManagerFormSchema } from './schemas';
import * as S from './styles';
import { AccountManagerFormType, GetValuesToSubmitType } from './types';

const AccountManagerPage = () => {
  const [submitForm, { isLoading: isSubmitting }] = useSubmitAccountManagerFormMutation();

  const { siteId } = useParams();
  const {
    data: siteDetails,
    isSuccess: siteDetailsSuccess,
    isLoading: siteDetailsLoading,
  } = useGetSiteDetailsQuery(siteId || '', {
    skip: !siteId,
  });

  const { data: accountManagerData, isLoading: accountManagerLoading } = useGetDynamicsAccountManagerQuery(
    {
      siteId: siteId as string,
      accountNumber: siteDetails?.accountNumber as string,
    },
    { skip: !siteId || !siteDetailsSuccess || !siteDetails?.accountNumber }
  );

  const isLoading = accountManagerLoading || siteDetailsLoading;

  const initialValues = {
    query: '',
    message: '',
  };

  const dropdownOptions = [
    { id: '1', name: 'Updating your audio' },
    { id: '2', name: 'Audio Branding Strategy Call' },
    { id: '3', name: 'Additional Services' },
    { id: '4', name: 'Billing & Payment' },
    { id: '5', name: 'Something else' },
  ];

  const getValuesToSubmit = (values: AccountManagerFormType): GetValuesToSubmitType => {
    if (siteDetails) {
      return {
        subject: dropdownOptions.find((x) => x.id === values.query)?.name ?? '',
        message: values.message,
        siteName: siteDetails.siteName,
        accountNumber: String(siteDetails.accountNumber),
        siteId: siteId ?? '',
      };
    }
    //@ts-ignore
    return {};
  };

  const handleSubmit = async (
    formValues: AccountManagerFormType,
    resetForm: (nextState?: Partial<FormikState<AccountManagerFormType>>) => void
  ) => {
    try {
      const values = getValuesToSubmit(formValues);
      const result = await submitForm(values).unwrap();
  
      if (result) {
        resetForm();
  
        createNotification({
          message: 'Message sent. Your account manager will be in touch.',
          type: 'success',
        });
      }
    } catch (error){
      createNotification({
        message: 'Failed to load. Please try again.',
        type: 'error',
      });
    }
  };

  return (
    <SidebarPageLayout pageTitle="PHMG | Account Manager" isLoading={isLoading}>
      <S.PageWrapper>
        {isLoading && (
          <S.SpinnerContainer>
            <Spinner />
          </S.SpinnerContainer>
        )}

        {!isLoading && accountManagerData && (
          <>
            <AccountManagerHero accountManagerName={accountManagerData.data.fullName} />

            <S.FormWrapper>
              <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => handleSubmit(values, actions.resetForm)}
                validateOnMount={true}
                enableReinitialize
                validationSchema={accountManagerFormSchema}>
                {({ isValid }) => (
                  <S.FormStyles>
                    <Dropdown name="query" options={dropdownOptions} label="Query Type" />
                    <S.InputStyled
                      label="Message"
                      type="text"
                      name="message"
                      multiLine
                      multiLineRows="10"
                      placeholder="Type message here..."
                    />
                    <S.SubmitWrapper>
                      <Button type="submit" disabled={isLoading || !isValid || isSubmitting} label={'Send message'}></Button>

                      {isSubmitting && <Spinner />}
                    </S.SubmitWrapper>
                  </S.FormStyles>
                )}
              </Formik>
            </S.FormWrapper>
          </>
        )}
      </S.PageWrapper>
    </SidebarPageLayout>
  );
};

export default AccountManagerPage;
