import { size } from '@shared/utils/breakpoints';
import styled, { css } from 'styled-components';

export const NavbarWrapper = styled.div`
  grid-area: header;
`;

export const NavbarStyles = styled.nav<{darkMode: boolean}>`
  background-color: var(--white);
  border-bottom: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.darkMode &&
    css`
      background-color: rgb(2, 2, 2);
    `}
`;

export const Brand = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  max-height: 26px;

  .logo {
    width: auto;
  }

  svg {
    width: 102px;
    height: 30px;
  }

  @media (min-width: ${size.medium}) {
    padding-left: 30px;

    svg {
      width: 133px;
      height: 35px;
    }
  }
`;

type SubNavProps = {
  subnavOpen?: boolean;
  darkMode: boolean;
};

export const NavLinks = styled.ul<SubNavProps>`
  list-style: none;
  display: flex;

  li {
    border-left: 1px solid var(--light-grey);
  }
`;

export const NavLink = styled.a<{active?: boolean; darkMode: boolean}>`
  height: 70px;
  display: flex;
  align-items: center;
  color: var(--black);
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  transition: 0.15s ease;
  position: relative;

  ${(props) =>
    props.darkMode &&
    css`
      color: var(--white);
    `}

  @media (min-width: ${size.medium}) {
    height: 80px;

    &::after {
      content: '';
      display: block;
      width: 0%;
      height: 3px;
      background-image: var(--red-pink-gradient);
      bottom: 1px;
      left: 0;
      position: absolute;
      transition: 0.3s ease;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    ${(props) =>
    props.active &&
      css`
        &::after {
          width: 100%;
        }
      `}
  }
`;

export const UserNavItem = styled.li`
  @media (max-width: ${size.large}) {
    display: none;
  }
`;

export const LogoutNavItem = styled.li`
  @media (max-width: ${size.large}) {
    display: none;
  }
`;

export const UserItem = styled.div`
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;

  @media (max-width: ${size.medium}) {
    display: none;
  }

  /* @media (max-width: ${size.medium}) {
    display: flex;
  } */
`;

export const LocationsText = styled.div`
  padding: 0 15px;
`;
