import { getFreshJWT } from '@api/interceptors';
import { useLoginMutation } from '@config/state/globalSlices/apiSlice/requests';
import { loginHandler } from '@config/state/globalSlices/userSlice';
import { Button } from '@phmg/storybook/v1/component';
import RightArrow from '@shared/icons/right-arrow.svg';
import { createNotification } from '@shared/utils/createNotification';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loginSchema } from '../loginSchema';
import * as S from './styles';

const initialValues = {
  email: '',
  password: '',
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isSuccess, isLoading, data, isError }] = useLoginMutation();

  const [username, setUsername] = useState('');
  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [setToken]
  );

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      if (!token) {return;}

      setUsername(values.email);
      login({ recaptchaToken: token, username: values.email, password: values.password });

      setRefreshReCaptcha((n) => !n);
    },
    [login, token]
  );

  useEffect(() => {
    if (!isSuccess || !data) {return;}

    if (data.result.ChallengeName) {
      if (data.result.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        const sessionId = data.result?.Session;
        navigate('/ap/onboarding', { state: { sessionId, username } });
      }

      if (data.result.ChallengeName === 'RESET_REQUIRED') {
        navigate('/ap/forgot-password');
        createNotification({
          message: 'Password reset required',
          type: 'error',
        });
      }
    } else {
      if (data.result.AuthenticationResult) {
        dispatch(loginHandler(data.result.AuthenticationResult));
        getFreshJWT();

        navigate('/locations');
      }
    }
  }, [isSuccess, data, username, dispatch, navigate]);

  let label = 'Log in';

  if (!isLoading && !isSuccess) {
    label = 'Log in';
  } else if (isLoading) {
    label = 'Logging in...';
  } else if (isSuccess) {
    label = 'Login successful!';
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={loginSchema}>
      <S.FormStyles>
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
        <S.SensitiveInputStyled label="Email/Username" type="text" name="email" />
        <S.SensitiveInputStyled label="Password" placeholder="*******" type="password" name="password" />
        <Button type="submit" variant="default" label={label} icon={RightArrow} />
        <S.ForgotPasswordLink to="/ap/forgot-password">Forgot Password</S.ForgotPasswordLink>
        {isError && (
          <S.ErrorMessage>
            Username and password combination is not recognised, 
            if you have already followed the instructions at the top of this page,
            please contact your account manager who will be happy to assist you.
          </S.ErrorMessage>
        )}
        <S.BottomText>
          Contact{' '}
          <a href="mailto:clientservices@phmg.com" target="_blank" rel="noreferrer">
            client services
          </a>{' '}
          to request an account
        </S.BottomText>
      </S.FormStyles>
    </Formik>
  );
};

export default LoginForm;
