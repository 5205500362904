import { Icon } from '@phmg/storybook/v1/component';

import S from './styles';

type Props = {
  accountManagerName: string;
};

export default function AccountManagerHero({ accountManagerName }: Props) {
  const formatName = (fullName: string) => {
    const name = fullName.trim();

    // Check if the name contains only letters, spaces, or hyphens
    // Return an empty string if any invalid characters are found
    if (/[^\sA-Za-z-]/.test(name)) {
      return '';
    }

    const cleanedName = name.replaceAll(/^-+|-+$|\s+/g, ' ').trim();

    const names = cleanedName.split(/\s+/);

    return names[0];
  };

  const name = formatName(accountManagerName);
  const hasName = accountManagerName !== '';

  return (
    <S.AccountManagerHeroContainer>
      <S.ImageContainer>
        <Icon glyph="account" size="100px" />
      </S.ImageContainer>

      {hasName ? (
        <>
          <h4>Hi, I'm {name}, your account manager.</h4>
          <p>
            If you have any questions about the client portal, your package or general queries, 
            fill the form below and I'll get back to
            you!
          </p>
        </>
      ) : (
        <>
          <h4>Get in touch with your Account Manager</h4>
          <p>
            If you have any questions about the client portal, your package or general queries, 
            fill the form below and your account manager
            will get back to you!
          </p>
        </>
      )}
    </S.AccountManagerHeroContainer>
  );
}
