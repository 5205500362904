import { size } from '@shared/utils/breakpoints';
import styled, { css } from 'styled-components';

export const NavbarStyles = styled.nav`
  width: 100%;
  height: 70px;
  background-color: var(--white);
  border-bottom: 1px solid var(--light-grey);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${size.medium}) {
    height: 80px;
  }
`;

export const Brand = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  max-height: 26px;

  svg {
    width: 102px;
    height: 30px;
  }

  @media (min-width: ${size.medium}) {
    padding-left: 30px;

    svg {
      width: 133px;
      height: 35px;
    }
  }
`;

export const BrandSeparator = styled.div`
  display: none;
  width: 1px;
  height: 80px;
  margin: 0 20px 0 30px;
  background-color: #979797;
  opacity: 0.5;

  @media (min-width: ${size.medium}) {
    display: initial;
  }
`;

export const BrandLink = styled.a`
  display: none;
  color: var(--black);
  text-decoration: none;
  font-weight: 300;
  transition: 0.15s ease;

  &:hover {
    opacity: 0.5;
  }

  @media (min-width: ${size.medium}) {
    display: initial;
  }
`;

export const NavLinks = styled.ul<{ subnavOpen?: boolean }>`
  list-style: none;
  display: flex;
`;

export const NavLink = styled.a<{ active: boolean }>`
  margin: 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  color: var(--black);
  font-size: 1rem;
  text-decoration: none;
  transition: 0.15s ease;
  position: relative;

  @media (min-width: ${size.medium}) {
    height: 80px;

    &::after {
      content: '';
      display: block;
      width: 0%;
      height: 3px;
      background-image: var(--red-pink-gradient);
      bottom: 1px;
      left: 0;
      position: absolute;
      transition: 0.3s ease;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    ${({ active }) =>
    active &&
      css`
        &::after {
          width: 100%;
        }
      `}
  }
`;

export const Subnav = styled.div`
  height: 50px;
  background-color: var(--white);
  border-bottom: 1px solid var(--light-grey);

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
`;

export const SubnavLinks = styled(NavLinks)`
  display: none;
  position: fixed;
  height: calc(100vh - 120px);
  width: 100vw;
  background-color: #f7f7f7;
  top: 104px;
  flex-direction: column;
  padding: 0px;
  z-index: 1;

  ${(props) =>
    props.subnavOpen &&
    css`
      display: flex;
    `}

  @media (min-width: ${size.medium}) {
    display: flex;
    position: initial;
    height: initial;
    width: initial;
    background-color: transparent;
    top: initial;
    flex-direction: row;
  }
`;

export const SubnavLink = styled(NavLink)`
  height: 60px;
  padding: 0 20px;
  margin: 0;
  background-color: white;
  border-bottom: 1px solid #e7e7e7;

  @media (min-width: ${size.medium}) {
    padding: initial;
    margin: 0 20px;
    border-bottom: none;
    background-color: transparent;
  }
`;

export const SubnavUpperLink = styled(SubnavLink)`
  height: 50px;
  position: relative;

  @media (min-width: ${size.medium}) {
    height: 60px;
  }
`;

export const SubnavTrigger = styled.div<{ subnavOpen?: boolean }>`
  position: absolute;
  height: 50px;
  width: 50px;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: 0.2s ease;
  }

  ${(props) =>
    props.subnavOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}

  @media(min-width: ${size.medium}) {
    display: none;
  }
`;
