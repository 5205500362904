import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface UserState {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  salesforceId: string;
  isLoggedIn: boolean;
}

const initialState: UserState = {
  username: '',
  email: '',
  firstname: '',
  lastname: '',
  salesforceId: '',
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginHandler: (state, action) => {
      state.isLoggedIn = true;
      localStorage.setItem('accessToken', action.payload.AccessToken);
      localStorage.setItem('refreshToken', action.payload.RefreshToken);
    },

    logoutHandler: (state) => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      Object.assign(state, initialState);
      window.location.pathname = '/';
    },

    updateCustomer: (state, action) => {
      state.username = action.payload.username;
      state.firstname = action.payload.firstName;
      state.lastname = action.payload.lastName;
      state.email = action.payload.email;
      state.salesforceId = action.payload.salesforceId;
      state.isLoggedIn = true;
    },
  },
});

export const { loginHandler } = userSlice.actions;
export const { logoutHandler } = userSlice.actions;
export const { updateCustomer } = userSlice.actions;

// Selectors
export const getUser = (state: RootState) => state.user;
export const getIsLoggedIn = (state: RootState) => state.user.isLoggedIn;

export default userSlice.reducer;
