import api from '..';
import { ProfileResponse, SubmitProfileFormParams } from '../types/profile';

export const profileMutationsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    submitProfileDetailsForm: build.mutation<ProfileResponse, SubmitProfileFormParams>({
      query: ({ title, firstName, lastName, email }) => ({
        url: '/profile',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'PUT',
        body: {
          title,
          firstName,
          lastName,
          email
        },
      }),
    }),
  }),
});
