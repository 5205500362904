import WaveSurferPlayer from './components/WaveSurferPlayer';

export interface Props {
  id: string;
  url: string;
  name: string;
}

const AudioPlayer = ({ id, url, name }: Props) => {

  const reformatId = (id: string): string => {
    // eslint-disable-next-line no-useless-escape
    const sanitized = id.replaceAll(/[^\w\-]/g, '');
    const prefixedSanitized = /^[^A-Za-z]/.test(sanitized) ? 'a' + sanitized : sanitized;
    return prefixedSanitized;
  };

  return (
    <>
      {url && name && id && (
        <WaveSurferPlayer
          id={reformatId(id)}  
          name={name}
          url={url}
        />
      )}
    </>
  );
}

export default AudioPlayer;
