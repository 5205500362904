import {
  useGetSiteCountiesQuery,
  useGetSiteCountryQuery,
  useGetSiteDetailsQuery,
  useSetSiteDetailsMutation,
} from '@config/state/globalSlices/apiSlice/requests';
import { Button, Dropdown } from '@phmg/storybook/v1/component';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import { H3 } from '@shared/components/Typography';
import { createNotification } from '@shared/utils/createNotification';
import * as GS from '@shared/utils/globalStyles';
import { Formik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { siteDetailsSchema } from './schema';
import * as S from './styles';

const LocationDetailsPage = () => {
  const { siteId } = useParams();
  const {
    data: siteDetails,
    isLoading,
    refetch,
  } = useGetSiteDetailsQuery(siteId || '', {
    skip: !siteId,
  });

  const { data: countiesOptions } = useGetSiteCountiesQuery({});
  const { data: countriesOptions } = useGetSiteCountryQuery({});

  const initialValues = {
    email: siteDetails?.emailAddress || '',
    addressLine1: siteDetails?.addressLine1 || '',
    addressLine2: siteDetails?.addressLine2 || '',
    country: siteDetails?.countryId || '',
    county: siteDetails?.countyId || '',
    postcode: siteDetails?.postcode || '',
    mobile: siteDetails?.mobile || '',
    phoneNumber: siteDetails?.telephone || '',
    city: siteDetails?.townCity || '',
  };

  const [updateSiteDetails, { isSuccess, isLoading: isSettingDetails }] = useSetSiteDetailsMutation();

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await updateSiteDetails({
        id: siteId || '',
        ...values,
      });
    },
    [updateSiteDetails, siteId]
  );

  useEffect(() => {
    if (isSuccess) {
      createNotification({
        message: 'Updated location details successfully',
        type: 'success',
      });
      refetch();
    }
  }, [isSuccess, isSettingDetails, refetch]);

  return (
    <SidebarPageLayout isLoading={isLoading} pageTitle="PHMG | Account">
      <GS.PageWrapper>
        <S.Header>
          <H3>Location Account Details</H3>
        </S.Header>
        {!isLoading && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={siteDetailsSchema}
            enableReinitialize
            validateOnMount={true}>
            <S.FormStyles>
              <S.InputStyled label="Email" type="email" name="email" />
              <S.InputStyled label="Phone Number" type="text" name="phoneNumber" />
              <S.InputStyled label="Mobile" type="text" name="mobile" />
              <S.InputStyled label="Address Line 1" type="text" name="addressLine1" />
              <S.InputStyled label="Address Line 2" type="text" name="addressLine2" />
              <S.InputStyled label="Town / City" type="text" name="city" />
              <Dropdown name="county" options={countiesOptions || []} label="COUNTY / STATE" />
              <Dropdown name="country" options={countriesOptions || []} label="COUNTRY" />
              <S.InputStyled label="POSTCODE / ZIP CODE" type="text" name="postcode" />
              <Button type="submit" disabled={isLoading} label={'Save'}></Button>
            </S.FormStyles>
          </Formik>
        )}
      </GS.PageWrapper>
    </SidebarPageLayout>
  );
};

export default LocationDetailsPage;
