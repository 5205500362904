import { BrandWorkshopTracksType, BrandWorkshopTrackType } from '@config/state/globalSlices/apiSlice/types/locations'

export const getTracksData = (brandWorkshopTracks: BrandWorkshopTracksType, brandName = '' ) => {

  return brandWorkshopTracks.map((track: BrandWorkshopTrackType) => {
    return {
      name: brandName,
      url: encodeURI(track.publicUrl),
      rationale: track.musicTrackRationale

    }
  })
}

export const getVoiceoversData = (voiceovers: any) => {

  return voiceovers.map((voiceover: any) => {
    return {
      name: voiceover.fullName,
      url: encodeURI(voiceover.url),
      rationale: voiceover.rationale
    }
  })
}
