import api from '..';
import { 
  ChangeTempPasswordParams,
  ChangeTempPasswordResponse, 
  ForgotPasswordParams, 
  SetPasswordFromProfilePageParams,
  SetPasswordParams, 
  SetPasswordResponse 
} from '../types/auth';

export const authMutationsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    changeTempPassword: build.mutation<ChangeTempPasswordResponse, ChangeTempPasswordParams>({
      query: ({ sessionId, newPassword, username }) => ({
        url: '/auth/change-temp-password',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          sessionId,
          newPassword,
          username,
        },
      }),
    }),
    forgotPassword: build.mutation<void, ForgotPasswordParams>({
      query: ({ username }) => ({
        url: '/auth/forgot-password',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          username,
        },
      }),
    }),
    setPassword: build.mutation<SetPasswordResponse, SetPasswordParams>({
      query: ({ username, newPassword, resetCode }) => ({
        url: '/auth/set-password',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          username,
          newPassword,
          resetCode
        },
      }),
    }),
    setPasswordFromProfilePage: build.mutation<void, SetPasswordFromProfilePageParams>({
      query: ({ oldPassword, newPassword }) => ({
        url: '/auth/change-password',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          oldPassword,
          newPassword,
        },
      }),
    }),

  }),
});
