import { Spinner } from '@phmg/storybook/v1/component';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import * as GS from './styles';

type Properties = {
  children: React.ReactNode;
  pageTitle: string;
  isLoading?: boolean;
  bgColor?: string;
};

const SidebarPageLayout = ({ children, pageTitle, isLoading = false, bgColor = '' }: Properties) => {
  return (
    <GS.PageContainer>
      <Navbar />
      <Helmet title={pageTitle} />
      <Sidebar />
      <GS.Container bgColor={bgColor}>
        
        {isLoading ? (
          <GS.SpinnerContainer>
            <Spinner />
          </GS.SpinnerContainer>
        ) : children}
      </GS.Container>
    </GS.PageContainer>
  );
};

export default SidebarPageLayout;
