import { useParams } from 'react-router-dom';

import * as S from './styles';

type Props = {
    siteName: string;
    previewMode: boolean;
};

const Hero = ({ siteName, previewMode }: Props) => {

  const { siteId, siteAccNumber } = useParams();

  return (
    <S.HeroWrapper>

      {
        !previewMode && (
          <S.DashboardLinkWrapper>
            <S.DashboardLink to={`/locations/${siteId}/${siteAccNumber}/brand-workshop`}>
  Your Brands
            </S.DashboardLink>
            <p > / Brand DNA</p>
          </S.DashboardLinkWrapper>
        )
      }

      <h2 className='brand-workshop-title'>
        Brand DNA for
      </h2>

      <p className='company'>
        {siteName}
      </p>
     
    </S.HeroWrapper>
  )
}

export default Hero;
