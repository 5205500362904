import { useForgotPasswordMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { Button, SensitiveInput } from '@phmg/storybook/v1/component';
import { H3 } from '@shared/components/Typography';
import { createNotification } from '@shared/utils/createNotification';
import { Form,Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { forgotPasswordSchema } from '../schemas';

const initialValues = {
  email: '',
};

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [submitForgotPasswordForm, { isLoading: isSubmitting, isSuccess, isError }] = useForgotPasswordMutation();

  const handleSubmit = async (values: {email: string}) => {
    try {
      await submitForgotPasswordForm({ username: values.email }).unwrap()

    } catch (error){
      createNotification({
        message: 'Something went wrong, please try again.',
        type: 'error',
      });
    }
  };

  return (
    <Formik 
      initialValues={initialValues} 
      onSubmit={handleSubmit} 
      validationSchema={forgotPasswordSchema}
      validateOnMount={true}>
      <Form>
        {!isSuccess && (
          <>
            <S.Heading>Password reset</S.Heading>
            <SensitiveInput label="Email/Username" name="email" type="text" placeholder="your@emailaddress.com" />
            <Button type="submit" wide centre disabled={isSubmitting} label={isSubmitting ? 'Resetting password...' : 'Forgot password'}></Button>
          </>
        )}

        {isSuccess && (
          <>
            <S.Heading>Check your email!</S.Heading>
            <S.BodyCopy>If we recognised your account, we’ve sent instructions to reset your password.</S.BodyCopy>
          </>
        )}

        <Button variant="ghost" wide centre onClick={() => navigate('/ap/login')} label="Back to login" />
        {isError && (
          <S.ErrorMessage>Oops! Looks like there was an issue submitting the form, please try again!</S.ErrorMessage>
        )
        }
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;

const S: any = {};

S.ErrorMessage = styled.p`
  color: var(--red);
  line-height: 1.5rem;
  font-weight: 300;
  margin: 20px 0 0;
`;

S.Heading = styled(H3)`
  text-align: center;
`;

S.BodyCopy = styled.div`
  font-size: 18px;
  text-align: center;
  margin: 40px 0;
`;
