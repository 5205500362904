import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRefresh } from './baseQueryWithRefresh';
import { Tags } from './types/tags';

const api = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithRefresh,
  endpoints: () => ({}),
  tagTypes: [
    Tags.Sites, 
    Tags.Countries, 
    Tags.Counties,
    Tags.Productions, 
    Tags.ProductionFiles, 
    Tags.User, Tags.Account, 
    Tags.AccountManagers, 
    Tags.Cases, 
    Tags.CaseMembers, 
    Tags.Files,
    Tags.Feedback
  ],
});

export default api;
