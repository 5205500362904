import { size } from '@shared/utils/breakpoints';
import { HiArrowSmallLeft } from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div<{marginRight?: string}>`
  grid-area: sidebar;
  background-color: #fff;
  width: 100%;
  position: relative;

  @media (max-width: ${size.large}) {
    display: none;
  }
`;

export const BackIcon = styled(HiArrowSmallLeft)`
  cursor: pointer;
`;

export const StyledNavLink = styled(NavLink)`
  display: inline-block;
  padding: 20px 20px 20px 25px;
  font-weight: 200;

  display: block;
  width: 100%;
  border-bottom: 1px solid var(--light-grey);
  position: relative;

  &.active,
  :hover {
    border-left: ${({ color }) => `7px solid ${color}`};
    padding-left: 20px;

    svg {
      fill: ${({ color }) => color};
    }
  }

  & svg {
    transition: fill 0.3s ease; // Smooth transition for color change
  }

  li {
    display: flex;
    gap: 18px;
    align-items: center;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const SideBarHeader = styled.div`
  margin: 20px 0px 30px 0;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const LocationName = styled.div`
  font-weight: 700;
`;

export const SideBarMenuWrapper = styled.div`
  ul {
    text-decoration: none;
    list-style-type: none;
  }

  p {
    font-size: 16px;
    color: black;
    font-weight: 500;
  }
`;

export const HelpCentre = styled.div`
  display: inline-block;
  padding: 20px 20px 20px 25px;

  width: 100%;
  border-bottom: 1px solid var(--light-grey);
  position: relative;

  a {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    width: 100%;
    color: black;
  }
`;
