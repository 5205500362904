import { Input } from '@phmg/storybook/v1/component';
import { size } from '@shared/utils/breakpoints';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
`;

export const FormStyles = styled(Form)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 20px;
  width: 100%;
  -webkit-box-align: baseline;
  align-items: baseline;

  @media (min-width: ${size.medium}) {
    margin: 0px;
    grid-column: 2 / -2;
  }
`;

export const Header = styled.div`
  grid-column: 1 / -1;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 30px;
`;

export const InputStyled = styled(Input)`
  margin-top: 0px;
  margin-bottom: 10px;

  @media (min-width: ${size.medium}) {
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  grid-column: 1 / -1;
`;

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubmitedMessage = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  color: inherit;
  text-decoration: none;
  transition: all 0.25s ease 0s;
  margin: 50px 0px;
  padding: 0.5rem 0.75rem;
  -webkit-box-align: center;
  align-items: left;
  cursor: pointer;
  width: 100%;
  min-height: 60px;
`;
