import { logoutHandler } from '@config/state/globalSlices/userSlice';
import { store } from '@config/state/store';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem('accessToken');

  if (config.headers) {
    (config.headers as Record<string, string>)['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export const onResponseError = async (error: AxiosError<{ message: string }>): Promise<AxiosError> => {
  if (error.response && // Access Token was expired
    //@ts-ignore
    error.response.status === 401 && error.response.data.message === 'jwt expired') {
    const refreshToken = localStorage.getItem('refreshToken');

    try {
      const rs = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/auth/refresh-token`, {
        refreshToken: refreshToken,
      });

      localStorage.setItem('accessToken', rs.data.data.AuthenticationResult.AccessToken);

      return Promise.resolve(
        axios({
          ...error.config,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        } as AxiosRequestConfig)
      ) as unknown as Promise<AxiosError>;
    } catch (_error: any) {
      if (_error.response.status === 401 && _error.response.data.message === 'refresh token expired') {
        store.dispatch(logoutHandler());
      }

      return Promise.reject(_error);
    }
  }
  throw error;
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  //@ts-ignore
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

export const getFreshJWT = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  // Access Token was expired
  try {
    const rs = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/auth/refresh-token`, {
      refreshToken: refreshToken,
    });

    localStorage.setItem('accessToken', rs.data.data.AuthenticationResult.AccessToken);
  } catch (_error: any) {
    if (_error?.response?.status === 401 && _error?.response?.data?.message === 'refresh token expired') {
      store.dispatch(logoutHandler());
    }
  }
};
