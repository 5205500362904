import { useLazyCreateCaseQuery } from '@config/state/globalSlices/apiSlice/requests';
import { CreatedCaseFormTypes } from '@config/state/globalSlices/apiSlice/types/salesforce';
import { Button, Dropdown, Spinner } from '@phmg/storybook/v1/component';
import { Formik } from 'formik';
import { useState } from 'react';
import { useCallback } from 'react';

import { H4 } from '../../Typography';
import { createCaseSchema } from './schema';
import * as S from './styles';

const CreateCaseForm = ({ AccountId, OwnerId }: CreatedCaseFormTypes) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const initialValues = {
    subject: '',
    message: '',
  };

  const subjectOptions = [
    { id: 'default', name: 'Select a subject ...' },
    { id: 'request', name: 'Request' },
    { id: 'update', name: 'Update' },
    { id: 'change', name: 'Change' },
  ];

  const [createCase] = useLazyCreateCaseQuery();

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    const asyncHandle = async () => {
      const { data: createdCase } = await createCase({
        AccountId,
        OwnerId,
        RecordTypeId: '0128d000001PYFxAAO',
        Case_Type__c: 'Demo',
      });
      if (createdCase?.success) {
        setIsSubmitting(false);
        setHasSubmitted(true);
        setIsSuccess(true);
      } else if (!createdCase?.success) {
        setIsSubmitting(false);
        setHasSubmitted(true);
        setIsSuccess(false);
      }
    };
    asyncHandle();
  }, [createCase, AccountId, OwnerId]);

  const resetForm = () => {
    setHasSubmitted(false);
  };

  return (
    <S.Container>
      {isSubmitting && (
        <S.SpinnerContainer>
          <Spinner />
        </S.SpinnerContainer>
      )}
      {!isSubmitting && hasSubmitted && (
        <S.SubmitedMessage>
          <H4>{isSuccess ? 'Successfully submitted request' : 'Failed to submit request'}</H4>
          <br />
          <Button type="submit" label={isSuccess ? 'Submit another' : 'Try again'} onClick={() => resetForm()}></Button>
        </S.SubmitedMessage>
      )}
      {!isSubmitting && !hasSubmitted && (
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmit();
            resetForm();
          }}
          validateOnMount={true}
          validationSchema={createCaseSchema}
          enableReinitialize>
          <S.FormStyles>
            <Dropdown name="subject" options={subjectOptions} label="Subject" />
            <S.InputStyled name="message" type="text" label="Message" />
            <Button type="submit" label={'Submit'}></Button>
          </S.FormStyles>
        </Formik>
      )}
    </S.Container>
  );
};

export default CreateCaseForm;
