import { useGetSiteDetailsQuery } from '@config/state/globalSlices/apiSlice/requests';

export const useSiteDetails = (siteId: string | undefined) => {
  const {
    data: siteDetails,
    isLoading: siteDetailsIsLoading,
    isError: siteDetailsisError,
  } = useGetSiteDetailsQuery(siteId || '', { skip: !siteId });

  const isLoading = siteDetailsIsLoading;

  return {
    siteDetails,
    isLoading,
    siteDetailsisError,
  };
};
