import { H3, H4 } from '@shared/components/Typography';
import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const LoginPageStyles = styled.main`
  background-color: var(--white);
  height: 100%;
  min-height: calc(100vh - 80px);
`;

export const LoginHeader = styled(H4)`
  margin: 0 0 26px;

  @media (min-width: ${size.medium}) {
    margin: 0 0 22px;
  }
`;

export const LoginContainer = styled.div`
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  padding: 28px 0;
  grid-row-gap: 30px;

  @media (min-width: ${size.medium}) {
    padding: 32px 0;
  }
`;

export const LoginSection = styled.div`
  grid-column: span 12;
  display: flex;
  flex-direction: column;

  @media (min-width: ${size.medium}) {
    grid-column: span 7;
  }

  @media (min-width: ${size.large}) {
    grid-column: span 4;
  }
`;

export const ImageSection = styled.img`
  grid-column: span 12;
  max-width: 100%;

  @media (min-width: ${size.large}) {
    grid-column: span 8;
  }
`;

export const TopSection = styled.section`
  @media (min-width: ${size.medium}) {
    border-bottom: 1px solid var(--light-grey);
  }
`;

export const FeaturedSection = styled.div`
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  padding: 0 0 40px;

  svg {
    height: 46px;
  }

  @media (min-width: ${size.medium}) {
    padding: 60px 0;
  }
`;

export const FeaturedTitle = styled(H3)`
  grid-column: 1 / -1;
  margin-bottom: 0px;
  text-align: center;

  @media (min-width: ${size.medium}) {
    margin-bottom: 10px;
  }
`;
