import { toast } from 'react-hot-toast';
import styled, { css, keyframes } from 'styled-components';

type CreateNotificationType = {
  message: string;
  type: 'success' | 'error';
};

export const createNotification = ({ message, type = 'success' }: CreateNotificationType) => {
  toast.custom(
    (t) => (
      <S.NotificationWrapper visible={t.visible} type={type}>
        {message}
      </S.NotificationWrapper>
    ),
    { duration: 9000 }
  );
};

const animateIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(0px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(-10px) scale(1);
  }
`;

const animateOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(-10px)
  }

  to {
    opacity: 0;
    transform: translateY(0px)
  }
`;

const S: any = {};

S.NotificationWrapper = styled.div`
  height: 50px;
  border-radius: 25px;
  padding: 0 57px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  opacity: 0;
  font-weight: 300;
  font-size: 1rem;
  text-align: center;

  ${(props: any) =>
    props.visible &&
    css`
      animation: ${animateIn} 0.2s ease-in-out;
      animation-fill-mode: forwards;
    `}

  ${(props: any) =>
    !props.visible &&
    css`
      animation: ${animateOut} 0.2s ease-in-out;
      animation-fill-mode: forwards;
    `}

  ${(props: any) =>
    props.type === 'success' &&
    css`
      background-image: var(--turquise-lime-gradient);
    `}

  ${(props: any) =>
    props.type === 'error' &&
    css`
      background-image: var(--red-pink-gradient);
      color: var(--white);
    `}

  @media(min-width: 768px) {
    font-size: 1.125rem;
  }
`;
