import api from '..';
import {
  AccountManagerSummary,
  AccountSummary,
  Case,
  CaseMembersRequest,
  CaseMembersSummary,
  CaseRequest,
  CasesByRecordRequest,
  CreatedCase,
  CreatedCaseRequest,
  CreatedFeedback,
  CreatedFeedbackRequest,
  UserSummary,
} from '../types/salesforce';
import { Tags } from '../types/tags';

const salesforceRequestsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getSFUser: build.query<UserSummary, string>({
      query: (salesforceId) => ({
        url: `/profile/sf/user/${salesforceId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.User],
      transformResponse: (data: UserSummary[]) => {
        return data[0];
      },
    }),
    getAccount: build.query<AccountSummary, string>({
      query: (accountId) => ({
        url: `/profile/sf/account/${accountId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.Account],
      transformResponse: (data: AccountSummary[]) => {
        return data[0];
      },
    }),
    getHeirarchyAccounts: build.query<AccountSummary[], string>({
      query: (ulitmateParentId) => ({
        url: `/profile/sf/account/heirarchy/${ulitmateParentId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.Account],
    }),
    getAccountManager: build.query<AccountManagerSummary, string>({
      query: (accountId) => ({
        url: `/profile/sf/accountManager/${accountId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.AccountManagers],
      transformResponse: (data: AccountManagerSummary[]) => {
        return data[0];
      },
    }),
    getCases: build.query<Case[], string>({
      query: (accountId) => ({
        url: `/profile/sf/cases/${accountId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: () => [{ type: Tags.Cases }],
    }),
    getCasesByType: build.query<Case[], CaseRequest>({
      query: ({ accountId, recordType, caseType }) => ({
        url: `/profile/sf/cases/${accountId}/${recordType}/${caseType}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: () => [{ type: Tags.Cases }],
    }),
    getCasesByRecordType: build.query<Case[], CasesByRecordRequest>({
      query: ({ accountId, recordType }) => ({
        url: `/profile/sf/cases/${accountId}/${recordType}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
    }),
    getCaseMembers: build.query<CaseMembersSummary[], CaseMembersRequest>({
      query: (accountId) => ({
        url: `/profile/sf/caseMembers/${accountId}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: [Tags.CaseMembers],
    }),
    getCaseFiles: build.query<string[], string[]>({
      query: (caseIds) => ({
        url: `/profile/sf/files/case?caseIds=${caseIds.join(',')}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
    }),
    getAccountFiles: build.query<string[], string>({
      query: (accountName) => ({
        url: `/profile/sf/files/account/${accountName}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
    }),
    getDownloadUrl: build.query<string, string>({
      query: (fileUrl) => ({
        url: `/profile/sf/files/download?file=${fileUrl}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
      providesTags: (result) => [{ type: Tags.Files, id: result }],
    }),
    createCase: build.query<CreatedCase, CreatedCaseRequest>({
      query: ({ AccountId, RecordTypeId, Case_Type__c, OwnerId }) => ({
        url: '/profile/sf/cases/createCase',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          AccountId: AccountId,
          RecordTypeId: RecordTypeId,
          Case_Type__c: Case_Type__c,
          OwnerId: OwnerId,
        },
      }),
      providesTags: (result) => [{ type: Tags.Cases, id: result?.id }],
    }),
    createFeedback: build.query<CreatedFeedback, CreatedFeedbackRequest>({
      query: ({ Case__c, Case_Owner__c, Action_Required__c, Feedback__c, Status__c }) => ({
        url: '/profile/sf/case/createFeedback',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        body: {
          Case__c: Case__c,
          Case_Owner__c: Case_Owner__c,
          Action_Required__c: Action_Required__c,
          Feedback__c: Feedback__c,
          Status__c: Status__c,
          Rejection_Reasons__c: '',
        },
      }),
      providesTags: (result) => [{ type: Tags.Feedback, id: result?.id }],
    }),
  }),
});

export default salesforceRequestsSlice;
