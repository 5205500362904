import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { store } from './config/state/store';

const container = document.querySelector('#root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY}>
    
      <Provider store={store}>
        <BrowserRouter>
          <App />
        
        </BrowserRouter>
        <Toaster position="bottom-left" />
      </Provider>

    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
