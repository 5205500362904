import { useLazyGetPDFInvoiceQuery } from '@config/state/globalSlices/apiSlice/requests';
import { Spinner } from '@phmg/storybook/v1/component';
import Table from '@shared/components/Table';
import DownArrow from '@shared/icons/down-arrow.svg';
import { createNotification } from '@shared/utils/createNotification';
import format from 'date-fns/format';

import * as S from './styles';

type InvoiceRowType = {
  invoiceId: string;
  type: string;
  date: string;
  status: string;
  number: string;
  siteId: string;
};

export default function InvoiceRow({ invoiceId, type, date, status, number, siteId }: InvoiceRowType) {
  const [downloadPdf, { isLoading }] = useLazyGetPDFInvoiceQuery();

  const handleDownloadPDF = async () => {
    try {
      const { data } = await downloadPdf({ siteId, invoiceId, type }).unwrap();

      if (data){

        // Attempt to convert the returned binary data into a Blob to construct a file URL from
        const blob = await fetch(`data:application/pdf;base64,${data?.binaryData}`).then((res) => res.blob());

        // Construct a URL from the Blob
        const fileUrl = window.URL.createObjectURL(blob);

        // Attempt to force a download of the PDF by manipulating DOM elements
        const anchor = document.createElement('a');

        anchor.download = data.fileName;
        anchor.href = fileUrl;
        anchor.click();

      }

    } catch (error) {
      createNotification({
        message: 'Failed to download PDF. Please try again.',
        type: 'error',
      });
    }
    
  }

  return (
    <Table.Row>
      <Table.Cell>{type}</Table.Cell>
      <Table.Cell>{format(new Date(date), 'dd MMMM yyyy')}</Table.Cell>
      <Table.Cell>{status}</Table.Cell>
      <Table.Cell>{number}</Table.Cell>
      <Table.Cell>
        {isLoading ? (
          <Spinner />
        ) : (
          <S.DownloadButton type="button" disabled={isLoading} onClick={handleDownloadPDF}>
            <span>Download</span> <img src={DownArrow} alt="" />
          </S.DownloadButton>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
