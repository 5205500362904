import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const Heading = styled.div`
  margin: 20px 0;
`;

export const PageWrapper = styled.div`
  padding: 20px;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  & > div {
    flex-basis: 100%;
  }

  @media (min-width: ${size.medium}) {
    flex-direction: row;
    & > div {
      flex-basis: 48%;
    }
  }

  @media (min-width: ${size.slightlyLarger}) {
    gap: 15px;
    & > div {
      max-width: 370px;
    }
  }
`;
