import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const AccountMangerWrapper = styled.div`

display: flex;
gap: 88px;
justify-content: center;
align-items: center;
padding: 85px;

.am-image-wrapper{
  width: 292px;
  height: 292px; 
  border-radius: 50%;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.am-image-wrapper > img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media (max-width: ${size.slightlyLarger}) {
  gap: 60px;
  padding: 85px 20px;

  .am-image-wrapper{
    width: 250px;
    height: 250px;
  }
}

@media (max-width: ${size.medium}) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 20px 40px 20px;
  gap: 40px;
}

@media (max-width: ${size.small}) {

gap: 24px;
padding: 20px 20px 40px 20px;


.am-image-wrapper{
    width: 90px;
    height: 90px;
  }

} 



`;

export const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  flex-basis: 1;

  h1{
    font-size: 38px;
    line-height: normal !important;
    color: #EDECED;
opacity: 1;
max-width: 380px;
word-break: break-word;

  }

  p{
    font-size: 16px;
    color: #FFFFFF;
    opacity: 0.5;
    word-break: break-word;
    max-width: 380px;
  }



  @media (max-width: ${size.small}) {

    gap: 16px;

    h1{
      font-size: 24px;
    }
  
  } 


`

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${size.medium}) {
  justify-content: center;
}

`;

export const Image = styled.div`
  flex-basis: 1;
`
