import { 
  useGetBrandWorkshopsQuery
} from '@config/state/globalSlices/apiSlice/requests';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import { useParams } from 'react-router-dom';

import Card from './components/card';
import * as S from './styles';

const LocationAccountPage = () => {
  const { siteId, siteAccNumber } = useParams();

  const {
    data: brandWorkshops,
    isLoading: brandWorkshopsLoading,
  } = useGetBrandWorkshopsQuery( {
    siteId: siteId as string,
    accountNumber: siteAccNumber as string,

  },
  { skip: !siteId || !siteAccNumber });

  const isLoading = (
    brandWorkshopsLoading
  )

  return (
    <SidebarPageLayout pageTitle={'Your Brands'} isLoading={isLoading}>
      <S.Root>

        <S.Wrapper>

          <S.Title>Your Brands</S.Title>

          {brandWorkshops?.data?.map((brand) => {
            return (
              <Card 
                key={brand.brandId} 
                brandId={brand.brandId} 
                title={brand.brandName} 
                siteId={siteId!}
                siteAccNumber={siteAccNumber!}/>
            )
          })}

        </S.Wrapper>
      
      </S.Root>
    </SidebarPageLayout>
  );
};

export default LocationAccountPage;
