import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const Root = styled.div`
  background-color: #151515;
  height: 100%;

  @media (max-width: ${size.slightlyLarger}) {
  height: calc(100vh - 71px)
  }
`

export const Title = styled.div`
 color: #fff;
 font-size: 40px;
 margin: 60px 0px 48px 0px;
`

export const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem;
`
