export enum FileStatus {
  awaitingApproval = 'awaiting-approval',
  approvedProductions = 'approved-productions',
}

export enum RecordTypes {
  PRODUCTION = 'Production',
  COPYWRITER = 'Copywriter',
  ENGAGEMENT = 'Engagement',
}

export enum CaseTypes {
  DEMO = 'Demo',
  JOB = 'Job',
}
