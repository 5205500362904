import RightArrow from '@shared/icons/right-arrow-gradient.svg';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';

type LocationListingType = {
  id: string;
  title: string;
};
const LocationListing = ({ id, title }: LocationListingType) => {
  const navigate = useNavigate();

  const changePage = () => {
    navigate(`/locations/${id}`, { state: { title } });
  };

  return (
    <div onClick={changePage} data-cy="siteOptions">
      <S.LocationListingStyles>
        <S.Title>{title}</S.Title>
        <S.Button>
          <img src={RightArrow} alt="" />
        </S.Button>
      </S.LocationListingStyles>
    </div>
  );
};

export default LocationListing;
