import { 
  useGetBrandWorkshopsQuery,
  useGetDynamicsAccountManagerQuery, 
} from '@config/state/globalSlices/apiSlice/requests';
import { Spinner } from '@phmg/storybook/v1/component';
import BrandWorkshop from '@shared/components/brandWorkshop/workshop';
import { useParams } from 'react-router-dom';

import * as S from './styles';

const BrandWorkshopPreviewPage = () => {

  const { siteId, siteAccNumber } = useParams();

  const {
    data: brandWorkshopsData,
    isLoading: brandWorkshopsLoading,
  } = useGetBrandWorkshopsQuery(
    {
      siteId: siteId as string,
      accountNumber: siteAccNumber as string,
    },
    { skip: !siteId || !siteAccNumber }
  );

  const {
    data: accountManagerData,
    isLoading: accountManagerLoading,
  } = useGetDynamicsAccountManagerQuery(
    {
      siteId: siteId as string,
      accountNumber: siteAccNumber as string,
    },
    { skip: !siteId || !siteAccNumber }
  );

  const isLoading = accountManagerLoading || brandWorkshopsLoading;

  if (isLoading){
    return (
      <S.LoadingState>
        <Spinner/>
      </S.LoadingState>
    )
  }

  return (
    <>

      {
        !isLoading && brandWorkshopsData && accountManagerData && (
          <BrandWorkshop
            brandWorkshopsData={brandWorkshopsData}
            accountManagerData={accountManagerData}
            previewMode={true}
          />
        )
      }

    </>
  );
};

export default BrandWorkshopPreviewPage;
