import { ChangeTempPasswordType } from '@api/types';
import { useChangeTempPasswordMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { Button, SensitiveInput } from '@phmg/storybook/v1/component';
import Navbar from '@shared/components/Navbar';
import { createNotification } from '@shared/utils/createNotification';
import { Form,Formik } from 'formik';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';

import { passwordFormSchema } from '../schema';
import * as S from '../styles';
import { InitialValuesType } from '../types';

const initialValues: InitialValuesType = {
  password: '',
  confirmPassword: '',
};

const NewUserSetPassPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [submitForm, { isLoading: isSubmitting, isSuccess, isError }] = useChangeTempPasswordMutation();

  const sessionId = state?.sessionId;
  const username = state?.username;

  useEffect(() => {
    if (!sessionId || !username) {
      navigate('/ap/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleSubmit = async (values: InitialValuesType) => {
    try {
      const val = {
        sessionId,
        newPassword: values.password,
        username,
      } as ChangeTempPasswordType;
      await submitForm(val).unwrap();

    } catch (error){
      createNotification({
        message: 'Session expired. Please re-login with your temporary password and try again.',
        type: 'error',
      });
      navigate('/ap/login');
    }
  };

  return (
    <>
      <Helmet title="PHMG | Change Password" />
      <Navbar />

      <S.SetPasswordWrapper>
        <S.FormWrapper>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={passwordFormSchema}>
            {({ isValid, dirty }) => (
              <Form>
                {!isSuccess && (
                  <>
                    <S.Heading>Welcome, it's time to set a new password</S.Heading>
                    <S.BodyCopy>

                      <p>
                      You have logged in with a temporary password. For security please set a new password below.
                      Once set please login with your new password.
                
                      </p><br/>

                      <p>Your Password Must Have:</p>

                      <S.Points>
                        <li>8 Characters or more</li>
                        <li>At least 1 lower case letter</li>
                        <li>At least 1 upper case letter</li>
                        <li>At least 1 number</li>
                      </S.Points>

                    </S.BodyCopy>
                    <SensitiveInput label="New Password" name="password" type="password" placeholder="********" />
                    <SensitiveInput label="Confirm New Password" name="confirmPassword" type="password" placeholder="********" />
                    <Button
                      type="submit"
                      wide
                      centre
                      disabled={ isSubmitting || isSuccess || !(dirty && isValid)}
                      label={isSubmitting? 'Resetting password...' : 'Set new password'}
                    />
                  </>
                )}

                {isSuccess && (
                  <>
                    <S.Heading>Password updated!</S.Heading>
                    <S.BodyCopy>You're all set. Please login with your new password.</S.BodyCopy>

                    <Button label="Back to login" wide centre onClick={() => navigate('/ap/login')} />
                  </>
                )}

                {isError && (
                  <S.ErrorMessage>Oops! Looks like there was an issue submitting the form, please try again!</S.ErrorMessage>
                )
            
                }
              </Form>
            )}
          </Formik>
        </S.FormWrapper>
      </S.SetPasswordWrapper>
    </>
  );
};

export default NewUserSetPassPage;
