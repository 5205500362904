import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { Button, Spinner } from '@phmg/storybook/v1/component';
import Navbar from '@shared/components/Navbar';
import { H3 } from '@shared/components/Typography';
import { size } from '@shared/utils/breakpoints';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import AccountForm from './components/AccountForm';
import SetPasswordForm from './components/SetPasswordForm';

const AccountPage = () => {
  const { data: userData, isLoading, refetch } = useGetUserQuery(undefined);

  return (
    <>
      <Helmet title="PHMG | Account" />
      <Navbar />

      <S.AccountWrapper>
        {isLoading && (
          <S.LoadingState>
            <Spinner />
          </S.LoadingState>
        )}

        {userData && (
          <>
            <S.Header>
              <S.Heading>Account details</S.Heading>
            </S.Header>

            <S.Body>
              <AccountForm userData={userData} refetch={refetch} />
              <SetPasswordForm />
            </S.Body>
          </>
        )}
      </S.AccountWrapper>
    </>
  );
};

export default AccountPage;

const S: any = {};

S.AccountWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

S.Header = styled.div`
  margin: 30px 0;
`;

S.Heading = styled(H3)`
  margin: 0;
`;

S.LoadingState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
`;

S.StyledButton = styled(Button)`
  display: none;

  @media (min-width: 768px) {
    display: initial;
  }
`;

S.MobileButton = styled(Button)`
  margin-bottom: 80px;

  @media (min-width: 768px) {
    display: none;
  }
`;

S.Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 10px;

  @media (min-width: ${size.large}) {
    flex-direction: row;
  }
`;
