import styled from 'styled-components';

export const WavesurferContainer = styled.div`
    background-color: #242424;
    border: 1px solid #8035FF;
    border-radius: 10px;
  
    transition: 0.25s ease;
    display: flex;
    align-items: center;
    
    padding: 7px 20px;
    align-items: center;
    width: 100%;
    gap: 10px;

    button{
        width: 100px;
        height: 100px;
    cursor: pointer;

        display: flex;
        justify-content: center;
        padding: 0;
        margin: auto;
        background: none;
        color: inherit;
        border: none;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        > * {
            margin: auto;
        }
    }
`;

export const TimeCounter = styled.div`
   
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: #fff;
`;

export const Wave = styled.div`
  min-height: 100%;
  width: 100%;
  cursor: pointer;
`
