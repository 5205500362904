import { useGetSiteDetailsQuery } from '@config/state/globalSlices/apiSlice/requests';
import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { OptionCard } from '@phmg/storybook/v1/component';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import { createNotification } from '@shared/utils/createNotification';
import * as GS from '@shared/utils/globalStyles';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import * as S from './styles';

const LocationAccountPage = () => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  // Location Details request hook
  const {
    data: siteDetails,
    isLoading: isLoadingSiteDetails,
    isError: isSiteDetailsError,
  } = useGetSiteDetailsQuery(siteId || '', { skip: !siteId });

  // Salesforce Id
  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetUserQuery();

  const salesforceId = userData?.salesforceId as string;

  const isLoading = isLoadingSiteDetails || userIsLoading;
  const isError = isSiteDetailsError || userIsError;

  const pageTitle = isLoading ? 'PHMG | Loading' : `PHMG | ${siteDetails?.siteName}`;

  useEffect(() => {
    if (!isLoading && isError) {
      createNotification({
        message: 'Failed to retrieve site data. Please try again.',
        type: 'error',
      });
    }
  }, [isError, isLoading]);

  const handleOnClick = useCallback(
    (href: string) => {
      navigate(href);
    },
    [navigate]
  );

  return (
    <SidebarPageLayout pageTitle={pageTitle} isLoading={isLoading}>
      <GS.PageWrapper>
        {!isLoading && (
          <>
            <S.Heading>
              <h3>Location details</h3>
            </S.Heading>

            <S.CardsWrapper data-cy="option-cards">
              <OptionCard
                data-cy="site-account-option-card"
                icon="Account"
                title="Location details"
                description="Review and update location details we hold for you, online in moments."
                href={`/locations/${siteId}/account/details`}
                onClick={handleOnClick}
              />
              {!isLoading && salesforceId && (
                <OptionCard
                  data-cy="location-account-manager-card"
                  icon="Account"
                  title="Account manager"
                  description="Review your account managers information."
                  href={`/locations/${siteId}/account/manager`}
                  onClick={handleOnClick}
                />
              )}
            </S.CardsWrapper>
          </>
        )}
      </GS.PageWrapper>
    </SidebarPageLayout>
  );
};

export default LocationAccountPage;
