import { H3 } from '@shared/components/Typography';
import styled from 'styled-components';

export const SetPasswordWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
`;

export const FormWrapper = styled.div`
  max-width: 400px;
  margin: 50px auto;
`;

export const ErrorMessage = styled.p`
  color: var(--red);
  line-height: 1.5rem;
  font-weight: 300;
  margin: 20px 0 0;
`;

export const Heading = styled(H3)`
  text-align: center;
`;

export const BodyCopy = styled.div`
  font-size: 18px;

  margin: 40px 0;
`;

export const Points = styled.ul `

  text-align: left;
  padding: 10px 0px 10px 40px;
  font-weight: 200;
`
