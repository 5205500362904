import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const RationaleWrapper = styled.div`
  width: 100%;
  padding: 18px 14px 18px 14px;

  @media (max-width: ${size.large}) {
    padding: 18px 0px 18px 0px;
 }

`

export const RationaleText = styled.p`
   max-width: 100% !important;
   font-size: 14px;
   margin-bottom: 20px !important;

   @media (max-width: ${size.large}) {
     font-size: 12px;
   }
`;

export const Button = styled.button`
  border: 1px solid #FFFFFF;
  border-radius: 36px;
  background-color: transparent;
  color: #FFFFFF;
  padding: 10px 18px;
  cursor: pointer;
`
