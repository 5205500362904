import styled from 'styled-components';

export const Wrapper = styled.div`
 position: relative;
`

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionOneWrapper = styled.div`

background: linear-gradient(to bottom, black, #5e00d7);
 padding-top: 40px;
 padding: 40px 20px 40px 20px;

`

export const AudioPlayer = styled.div`
color: black;
background-color: white;
padding: 4rem 0px;

@media only screen and (max-width: 600px) {
  padding: 0rem 0px 4rem;
    }
`;

export const PreviewNotice = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 20px;
  background-color: red;

  p {
    font-weight: 700;
  }
`
