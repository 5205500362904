import { useSubmitBrandWorkshopFeedbackMutation } from '@config/state/globalSlices/apiSlice/mutations';
import { useGetSiteDetailsQuery } from '@config/state/globalSlices/apiSlice/requests';
import { Button } from '@phmg/storybook/v1/component';
import { Spinner } from '@phmg/storybook/v1/component';
import { createNotification } from '@shared/utils/createNotification';
import { Formik, FormikState } from 'formik';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

import { accountManagerFormSchema } from './schemas';
import * as S from './styles';
import { AccountManagerFormType, GetValuesToSubmitType } from './types';

type Props = {
  isOpen: boolean;
  setOpen: (val: boolean) => void;
  brandName: string;
};

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    maxWidth: '443px',
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
    padding: '10px 18px 10px 18px',
    fontWeight: '500',
    zIndex: '500',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
    zIndex: '500',
  },
};

const AnnouncementModal = ({ isOpen, setOpen, brandName }: Props) => {
  const handleClose = () => {
    setOpen(!isOpen);
  };

  const [submitForm, { isLoading: isSubmitting }] = useSubmitBrandWorkshopFeedbackMutation();

  const { siteId, siteAccNumber } = useParams();
  const {
    data: siteDetails,
    isLoading: siteDetailsLoading,
  } = useGetSiteDetailsQuery(siteId || '', {
    skip: !siteId,
  });

  const isLoading = siteDetailsLoading;

  const initialValues = {
    query: '',
    message: '',
  };

  const getValuesToSubmit = (values: AccountManagerFormType): GetValuesToSubmitType => {
    if (siteDetails) {
      return {
        message: values.message,
        siteName: siteDetails.siteName,
        accountNumber: String(siteAccNumber),
        siteId: siteId ?? '',
        brandName
      };
    }
    //@ts-ignore
    return {};
  };

  const handleSubmit = async (
    formValues: AccountManagerFormType,
    resetForm: (nextState?: Partial<FormikState<AccountManagerFormType>>) => void
  ) => {
    try {
      const values = getValuesToSubmit(formValues);
      const result = await submitForm(values).unwrap();
  
      if (result) {
        resetForm();
  
        createNotification({
          message: 'Feedback sent.',
          type: 'success',
        });

        handleClose();
      }
    } catch (error){
      createNotification({
        message: 'Failed to load. Please try again.',
        type: 'error',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <S.ModalContainer>
        <S.Heading>
          <h3>Send us your feedback</h3>

        </S.Heading>

        <S.ModalContent>

          <S.FormWrapper>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, actions) => handleSubmit(values, actions.resetForm)}
              validateOnMount={true}
              enableReinitialize
              validationSchema={accountManagerFormSchema}>
              {({ isValid }) => (
                <S.FormStyles>
                  {/* <Dropdown name="query" options={dropdownOptions} /> */}
                  <S.InputStyled
                    type="text"
                    name="message"
                    multiLine
                    multiLineRows="10"
                    placeholder={`Write feedback here for ${brandName}...`}
                  />
                  
                  <S.SubmitWrapper >
                    <Button 
                      type="submit"
                      disabled={isLoading || !isValid || isSubmitting} 
                      label={'Send feedback'} 
                      padding="0px 15px"
                      variant='purple'/>

                    {isSubmitting && <Spinner />}

                    {
                      !isSubmitting && (
                        <Button  
                          disabled={isSubmitting} 
                          label={'Cancel'} 
                          onClick={() => handleClose()}
                          padding="0px 20px"
                          variant='cancel'
                        
                        />
                      )
                    }

                  </S.SubmitWrapper>

                </S.FormStyles>
              )}
            </Formik>
          </S.FormWrapper>
     
        </S.ModalContent>
      </S.ModalContainer>
    </Modal>
  );
};

export default AnnouncementModal;
