import * as S from './styles';

type Props = {
  setAMModalOpen: () => void;
};

const StickyFooter = ({ setAMModalOpen }: Props) => {

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <p>Want to share feedback on any of your Brand DNA?</p>
        <S.FeedbackButton onClick={setAMModalOpen}>
          Send feedback
        </S.FeedbackButton>
      </S.InnerWrapper>
    </S.Wrapper>
  )
}

export default StickyFooter;
