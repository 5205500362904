import { 
  useGetBrandWorkshopsQuery,
  useGetDynamicsAccountManagerQuery, 
} from '@config/state/globalSlices/apiSlice/requests';
import BrandWorkshop from '@shared/components/brandWorkshop/workshop';
import SidebarPageLayout from '@shared/components/SidebarPageLayout';
import { useParams } from 'react-router-dom';

const BrandWorkshopPage = () => {

  const { siteId, siteAccNumber } = useParams();

  const {
    data: brandWorkshopsData,
    isLoading: brandWorkshopsLoading,
  } = useGetBrandWorkshopsQuery(
    {
      siteId: siteId as string,
      accountNumber: siteAccNumber as string,
    },
    { skip: !siteId || !siteAccNumber }
  );

  const {
    data: accountManagerData,
    isLoading: accountManagerLoading,
  } = useGetDynamicsAccountManagerQuery(
    {
      siteId: siteId as string,
      accountNumber: siteAccNumber as string,
    },
    { skip: !siteId || !siteAccNumber }
  );

  const isLoading = accountManagerLoading || brandWorkshopsLoading;

  return (
    <SidebarPageLayout pageTitle="PHMG | DNA" isLoading={isLoading}>

      {
        brandWorkshopsData && accountManagerData && (
          <BrandWorkshop
            brandWorkshopsData={brandWorkshopsData}
            accountManagerData={accountManagerData}
          />
        )
      }

    </SidebarPageLayout>
  );
};

export default BrandWorkshopPage;
