import { Icon } from '@phmg/storybook/v1/component';
import { useCallback, useEffect, useRef, useState } from 'react';

import * as S from '../styles';
import { AudioPlayerProps } from '../types';
import useWavesurfer from './useWavesurfer';

export default function WaveSurferPlayer(props: AudioPlayerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [DurationTime, setDurationTime] = useState('');

  const wavesurfer: any = useWavesurfer(containerRef, props);

  const formatTime = function (time: number) {
    return [
      Math.floor((time % 3600) / 60),
      ('00' + Math.floor(time % 60)).slice(-2)
    ].join(':');
  };

  const onPlayClick = useCallback(() => {
    wavesurfer?.isPlaying() ? wavesurfer?.pause() : wavesurfer?.play();
  }, [wavesurfer]);

  useEffect(() => {
    if (!wavesurfer) { return; }

    setCurrentTime('0');
    setIsPlaying(false);

    const subscriptions = [
      wavesurfer.on('play', () => setIsPlaying(true)),
      wavesurfer.on('pause', () => setIsPlaying(false)),
      wavesurfer.on('timeupdate', function () {
        setCurrentTime(formatTime(wavesurfer.getCurrentTime()));
      }),
      wavesurfer.on('ready', function () {
        setDurationTime(formatTime(wavesurfer.getDuration()));
      }),
    ];

    return () => {
      subscriptions.forEach((unsub) => unsub());
    };
  }, [wavesurfer]);

  return (
    <S.WavesurferContainer>
      <button onClick={onPlayClick}>
        {isPlaying ?
          <Icon glyph='pause' size='54px' />
          : <Icon glyph='play' size='54px' />
        }
      </button>

      <S.Wave id={props.id} ref={containerRef} />
      <S.TimeCounter>
        <div>{currentTime}</div>
        <div>/</div>
        <div>{DurationTime}</div>
      </S.TimeCounter>
    </S.WavesurferContainer>
  );
}
