import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);
export const accountSchema = yup.object().shape({
  title: yup.string().required('You must enter a title'),
  firstName: yup.string().required('You must enter a first name'),
  lastName: yup.string().required('You must enter a last name'),
  email: yup.string().email('Enter a valid email address').required('An email address is required'),
});

export const setPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Password must contain 8 or more characters with at least one of each: uppercase, lowercase and number')
    .min(8, 'Password is too short')
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number'),
  currentPassword: yup.string().when('newPassword', {
    is: (val: string) => val?.length > 0,
    then: yup.string().required('Current password is required'),
  }),
  confirmNewPassword: yup.string().when('newPassword', {
    is: (val: string) => val?.length > 0,
    then: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .required('Password confirmation is required'),
  }),
});
