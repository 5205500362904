/* eslint-disable @typescript-eslint/no-unused-vars */
import { CaseTypes, RecordTypes } from '@config/state/globalSlices/apiSlice/constants';
import { useGetAccountFilesQuery, useGetAccountQuery, useGetCasesByTypeQuery } from '@config/state/globalSlices/apiSlice/requests';
import { useGetUserQuery } from '@config/state/globalSlices/apiSlice/requests';
import { getIsLoggedIn, logoutHandler } from '@config/state/globalSlices/userSlice';
import { createNotification } from '@shared/utils/createNotification';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import NavbarComponent from './components/Navbar';
import NavbarActive from './components/NavbarActive';

interface Props {
  darkMode?: boolean;
}

const Navbar = ({ darkMode = false }: Props) => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();
  const VITE_SF_FF_ENABLED = JSON.parse(import.meta.env.VITE_SF_FF_ENABLED);
  const [hasProductions, setHasProductions] = useState<boolean>(false);
  const location = useLocation();

  // User Data

  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useGetUserQuery(undefined, { skip: !isLoggedIn });

  const accountId = userData?.salesforceId as string;

  // Account Details
  const {
    data: accountDetails,
    isLoading: accountDetailsIsLoading,
    isError: accountDetailsIsError,
  } = useGetAccountQuery(accountId, {
    skip: !accountId || !VITE_SF_FF_ENABLED,
  });
  const accountName = accountDetails?.Name;

  const skipCasesCall = () => {
    return !accountId || location.pathname === '/locations' || location.pathname === '/account'
  }

  const isLoading = userIsLoading || accountDetailsIsLoading;

  // // AWAITING APPROVAL Productions
  // // Copywriter
  // const {
  //   data: copywriterCaseData,
  //   isLoading: copywriterCaseIsLoading,
  //   isError: copywriterCaseIsError,
  // } = useGetCasesByTypeQuery(
  //   { accountId: accountId, recordType: RecordTypes.COPYWRITER, caseType: CaseTypes.DEMO },
  //   { skip: skipCasesCall() || !accountId }
  // );
  // const copywriterCaseNumber = copywriterCaseData?.[0]?.CaseNumber;
  // // Music and Voiceover
  // const {
  //   data: productionCaseData,
  //   isLoading: productionCaseIsLoading,
  //   isError: productionCaseIsError,
  // } = useGetCasesByTypeQuery(
  //   { accountId: accountId, recordType: RecordTypes.PRODUCTION, caseType: CaseTypes.DEMO },
  //   { skip: skipCasesCall() || !accountId }
  // );
  // const productionCaseNumber = productionCaseData?.[0]?.CaseNumber;

  // // APPROVED PRODUCTIONS
  // const {
  //   data: accountFiles,
  //   isLoading: accountFilesIsLoading,
  //   isError: accountFilesIsError,
  // } = useGetAccountFilesQuery(accountName || '', { skip: !accountName || !accountId });

  // // Is Loading
  // const isLoading = (
  //   userIsLoading && 
  //   accountDetailsIsLoading && accountFilesIsLoading && copywriterCaseIsLoading && productionCaseIsLoading)

  // if ((copywriterCaseNumber || productionCaseNumber || accountFiles) && !hasProductions) {
  //   setHasProductions(true);
  // }

  // // ERROR NOTIFICATIONS
  // useEffect(() => {
  //   if (userIsError && !userIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch user details. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (accountDetailsIsError && !accountDetailsIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch account details. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (accountFilesIsError && !accountFilesIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch approved productions. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (productionCaseIsError && !productionCaseIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch awaiting approval productions. Please try again.',
  //       type: 'error',
  //     });
  //   }
  //   if (copywriterCaseIsError && !copywriterCaseIsLoading) {
  //     createNotification({
  //       message: 'Failed to fetch scripts. Please try again.',
  //       type: 'error',
  //     });
  //   }
  // }, [
  //   userIsError,
  //   userIsLoading,
  //   accountDetailsIsError,
  //   accountDetailsIsLoading,
  //   accountFilesIsError,
  //   accountFilesIsLoading,
  //   productionCaseIsError,
  //   productionCaseIsLoading,
  //   copywriterCaseIsError,
  //   copywriterCaseIsLoading
  // ]);

  const handleLogout = useCallback(() => {
    dispatch(logoutHandler());
  }, [dispatch]);

  return (
    <>
      {!isLoggedIn && <NavbarComponent brandlink={import.meta.env.VITE_MAIN_SITE_URL} />}

      {isLoggedIn && (
        <NavbarActive
          siteId={accountDetails?.Id}
          logout={() => handleLogout()}
          site={accountDetails?.Name || ''}
          hasProductions={hasProductions}
          isLoading={isLoading}
          darkMode={darkMode}
        />
      )}
    </>
  );
};

export default Navbar;
