import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

export const passwordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password must contain 8 or more characters with at least one of each: uppercase, lowercase and number')
    .min(8, 'Password is too short')
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number'),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});
