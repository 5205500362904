import { size } from '@shared/utils/breakpoints';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeroWrapper = styled.div`
    text-align: center;
    color: #fff;
    background-image: url('https://phmg-styles.s3.eu-west-2.amazonaws.com/images/background-1.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;  
    align-items: center;
    flex-direction: column;
    margin: 0px 20px 40px 20px;
    border-radius: 16px;
    padding: 70px;
    
    .brand-workshop-title{
      font-size: 38px;
      display: inline;
    }


    .company {
      font-size: 38px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1;
   
    }


    @media (max-width: ${size.medium}) {
  
      .brand-workshop-title{
        font-size: 25px;
      }

      .company {
        font-size: 25px;
        line-height: 0.5;
     }
    }

    @media (max-width: ${size.small}) {
      margin: 0px 10px 40px 10px;

     .brand-workshop-title{
        font-size: 24px;
      }

      .company {
        font-size: 24px;
        line-height: 0.3;
     }
    }

`;

export const DashboardLinkWrapper = styled.div`
  margin-bottom: 20px;

  p {
    display: inline;
    font-size: 17px;
  }
`
export const DashboardLink = styled(Link)`
  font-size: 17px;
  text-decoration: underline;
  color: #fff;
`
