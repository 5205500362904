import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`

  z-index: 1000;
  position: sticky;
  bottom: 16px;
  height: 65px; 
  background: transparent linear-gradient(104deg, #8035FF 0%, #8900FF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000019;
  border-radius: 12px;
  margin: 0 16px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28px;
  height: 100%;
  gap: 5px;

  p{
    color: white;
    font-size: 18px;
  }

  @media (max-width: ${size.medium}) {
  p{
    font-size: 14px;
    line-height: normal;
  }
}
`

export const FeedbackButton = styled.div`
  appearance: none !important;
  background-color: #fff;
  height: 40px;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 25px;
  border: none;
  color: #8900FF;
  text-align: center;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
`
