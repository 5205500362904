import { useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

import { AudioPlayerProps } from '../types';

export default function useWavesurfer(containerRef: any, options: AudioPlayerProps) {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);

  useEffect(() => {
    if (!containerRef.current) { return; }

    const ws: WaveSurfer = WaveSurfer.create({
      ...options,
      barWidth: 2,
      cursorWidth: 1,
      height: 44,
      progressColor: '#8a58e0',
      waveColor: '#402966',
      cursorColor: '#fff',
      container: '#' + options.id,
    });

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [options, containerRef]);

  return wavesurfer;
}
