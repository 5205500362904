import api from '..';
import { ProfileResponse } from '../types/profile';

const profileRequestsSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<ProfileResponse, void>({
      query: () => ({
        url: '/profile',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }),
    }),
  }),
});

export default profileRequestsSlice;
