import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const AudioWrapper = styled.div`

  background-color: black;
  border-top: 1px solid #373737;
  border-bottom: 1px solid #373737;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 120px;
  width: 100%;
  text-align:center;
  color: white;
  

  h2 {
    font-size: 38px;
    margin-bottom: 40px;
  }

  p {
    color: #FFFFFF;
    opacity: 0.5;
    word-break: break-word;
    max-width: 500px;
    margin-bottom: 40px;
  }

  .audio-icon {
    width: 64px;
    height: auto;
    margin-bottom: 20px;
  }

  @media (max-width: ${size.slightlyLarger}) {
    padding: 80px 20px;
  }

`;

export const Player = styled.div`
  width: 100%;
  background-color: #242424;
  border-radius: 16px;
  padding: 20px 80px 40px 80px;

  h3 {
    margin-bottom: 37px;
    font-size: 25px;
    font-weight: bold;
  }

  .player-inner {
    min-width: 200px;
    width: 100%;
  }

  @media (max-width: ${size.slightlyLarger}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 40px;
  }
`

export const TrackWrapper = styled.div`
  margin: 20px 0;
`
