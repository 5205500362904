import { getIsLoggedIn } from '@config/state/globalSlices/userSlice';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const PrivateRoute = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const location = useLocation();

  return isLoggedIn ? <Outlet /> : <Navigate to="/ap/login" replace state={{ redirect: location.pathname }} />;
};
