import { size } from '@shared/utils/breakpoints';
import styled from 'styled-components';

export const AccountManagerWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;

  @media (min-width: ${size.medium}) {
    padding: 0;
  }
`;

export const Header = styled.div`
  grid-column: 1 / -1;
  margin: 40px 0;
`;

export const AccountManagerCard = styled.div`
  background-color: var(--white);
  border: 1px solid var(--light-grey);
  border-radius: 10px;
  color: inherit;
  text-decoration: none;
  transition: all 0.25s ease 0s;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  padding: 0.5rem 0.75rem;
  gap: 2rem;
  -webkit-box-align: center;
  align-items: left;
  cursor: pointer;
  width: 100%;
  min-height: 60px;

  .field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 2.5;
    margin: 2% 10%;
  }
`;

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  grid-column: 1 / -1;
  overflow: auto;
  margin: 20px auto;

  tr {
    display: flex;
    justify-content: space-between;
    th,
    td {
      display: flex;
      justify-content: center;
      margin: auto;
    }
    th:nth-child(odd),
    td:nth-child(odd) {
      flex: 0 20%;
    }
    th:nth-child(even),
    td:nth-child(even) {
      flex: 0 40%;
    }
  }
`;
