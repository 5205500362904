import { getLocationMenuItems } from '@config/locationMenuItems';
import { useGetBrandWorkshopVisibleQuery } from '@config/state/globalSlices/apiSlice/requests';
import { useSiteDetails } from '@hooks/useSiteDetails';
import { Icon } from '@phmg/storybook/v1/component';
import { useCallback } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import * as S from './styles';

interface Props {
  menuOpenProp: boolean;
  logout: () => void;
  darkMode: boolean;
  hasProductions: boolean;
}

/**
 * Primary UI component for user interaction
 */
const BurgerMenu = ({ logout, menuOpenProp, darkMode, hasProductions }: Props) => {
  const [menuOpen, setMenuOpen] = useState(menuOpenProp);
  const { siteId } = useParams();

  const { siteDetails } = useSiteDetails(siteId);

  const {
    data: showBrandWorkshop,
    isLoading,
  } = useGetBrandWorkshopVisibleQuery( {
    siteId: siteId as string,
    accountNumber: siteDetails?.accountNumber as string,

  },
  { skip: !siteId || !siteDetails?.accountNumber });

  const siteAccNumber = siteDetails?.accountNumber

  const locationMenuItems = (
    getLocationMenuItems(siteId!, siteAccNumber!, hasProductions, Boolean(showBrandWorkshop)).filter((x) => x.enabled)
  )

  const handleDropDown = useCallback(() => {
    const body = document.querySelector('body') as HTMLBodyElement;
    body.style.overflow = 'initial';
    body.style.position = 'initial';
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  return (
    <S.AccountDropdownStyles menuOpen={menuOpen}>
      <S.AccountDropdownTrigger data-cy="account-dropdown" onClick={handleDropDown} darkMode={darkMode}>
        <Icon glyph="burger" size="10px" />
      </S.AccountDropdownTrigger>
      <S.MobileDropdownTrigger onClick={handleDropDown} darkMode={darkMode}>
        {menuOpen && <Icon glyph="Close" />}
        {!menuOpen && <Icon glyph="burger" />}
      </S.MobileDropdownTrigger>
      <S.DropdownMenu darkMode={darkMode}>
        {!isLoading && locationMenuItems.map(({ title, href, color, glyph }) => (
          <S.StyledNavLink to={href} key={title} color={color} end>
            <li>
              <Icon glyph={glyph} />
              <p>{title}</p>
            </li>
          </S.StyledNavLink>
        ))}

        <S.LogoutButton onClick={() => logout()}>
          <li>
            <Icon glyph="exit" />
            <p>Log out</p>
          </li>
        </S.LogoutButton>
      </S.DropdownMenu>
    </S.AccountDropdownStyles>
  );
};

export default BurgerMenu;
