export enum Tags {
    Sites = 'sites',
    Countries = 'countries',
    Counties = 'counties',
    Productions = 'productions',
    ProductionFiles = 'productionFiles',
    User = 'user',
    Account = 'account',
    AccountManagers = 'accountManagers',
    Cases = 'cases',
    CaseMembers = 'caseMembers',
    Files = 'files',
    Feedback = 'feedback'
}
