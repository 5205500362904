import { Input } from '@phmg/storybook/v1/component';
import { size } from '@shared/utils/breakpoints';
import { Form } from 'formik';
import styled from 'styled-components';

export const AccountManagerWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
`;

export const PageWrapper = styled.div`
  background-color: #edeced;
  height: 100%;
`;

export const FormWrapper = styled.div`
  padding: 43px 64px;
  background-color: #edeced;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${size.small}) {
    padding: 20px 20px;
  }

  @media (max-width: ${size.medium}) {
    padding: 20px 20px;
  }
`;

export const FormStyles = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 60%;

  @media (max-width: ${size.large}) {
    width: 100%;
  }
`;

export const InputStyled = styled(Input)`
  margin-top: 0px;
  margin-bottom: 10px;
`;

export const SpinnerContainer = styled.div`
  grid-column: 1 / -1;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
