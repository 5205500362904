import { authMutationsSlice } from './auth';
import { profileMutationsSlice } from './profile'
import { siteMutationsSlice } from './sites';

export const { 
  useSubmitAccountManagerFormMutation,
  useSubmitBrandWorkshopFeedbackMutation,
} = siteMutationsSlice;

export const {
  useSubmitProfileDetailsFormMutation
} = profileMutationsSlice;

export const {
  useChangeTempPasswordMutation,
  useForgotPasswordMutation,
  useSetPasswordMutation,
  useSetPasswordFromProfilePageMutation,
} = authMutationsSlice;
